import './App.css'
import { ApolloClient, InMemoryCache, ApolloProvider, ApolloLink, HttpLink } from '@apollo/client'
import CheckAuth from './components/CheckAuth'
import Cookies from 'universal-cookie'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { relayStylePagination } from '@apollo/client/utilities'
import './base/global.scss'
import { ThemeProvider } from '@mui/material'
import { theme } from './settings/theme'
import { CookieConsentProvider } from '@use-cookie-consent/react'

import ReactGA4 from 'react-ga4'

ReactGA4.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID)

const cookies = new Cookies()
const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            'access-token': cookies.get('BUNTON_ACCESS_TOKEN'),
            'token-type': 'Bearer',
            client: cookies.get('BUNTON_CLIENT_TOKEN'),
            expiry: cookies.get('BUNTON_EXPIRY'),
            uid: cookies.get('BUNTON_UID')
        }
    }
})

const baseLink = ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.map(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
            )
        if (networkError) console.log(`[Network error]: ${networkError}`)
    }),
    new HttpLink({
        uri: process.env.REACT_APP_GRAPHQL_URL,
        credentials: 'same-origin'
    })
])

const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                companies: relayStylePagination(),
                jobsListings: relayStylePagination(),
                getCandidateAppliedJob: relayStylePagination(),
                suggestedJobs: relayStylePagination(),
                getRecommendedCandidates: relayStylePagination(),
                getConnectionRequests: relayStylePagination(),
                getInvitationRequests: relayStylePagination(),
                getCompaniesActiveJobs: relayStylePagination(),
                getCandidateLikedJobs: relayStylePagination(),
                getContactUsData: relayStylePagination()
                // users: relayStylePagination()
            }
        }
    }
    // introspectionQueryResultData
})

const client = new ApolloClient({
    link: authLink.concat(baseLink),
    cache
})

function App() {
    return (
        <div className='App'>
            <CookieConsentProvider>
                <ThemeProvider theme={theme}>
                    <ApolloProvider client={client}>
                        <CheckAuth />
                    </ApolloProvider>
                </ThemeProvider>
            </CookieConsentProvider>
        </div>
    )
}

export default App
