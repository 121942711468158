/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Box, Typography, Button as MuiButton, Container } from '@mui/material'
import { Button, LinearProgressBar } from '../../../components'
import Icons from '../../../components/Icons'
import { BUTTON_DATA, keys, TextStrings } from '../../../constants'
import variables from '../../../settings/_variables.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import DoneIcon from '@mui/icons-material/Done'
import { useEffect, useState } from 'react'
import { styles } from './styles'
import Layout from '../../../template/Layout'
import PageMetaTags from '../../../base/PageMetaTags'

const ProfileSetupStepOne = props => {
    const navigate = useNavigate()
    const location = useLocation()
    const initialProgress = 20
    const nextProgress = 16
    const { profileDetail } = useSelector(state => state.profileDetail)
    const [progress, setProgress] = useState(initialProgress)
    /** updating progress on profile detail change */
    useEffect(() => {
        let tempProgress = initialProgress
        BUTTON_DATA?.map(item => {
            if (profileDetail[item.key] && profileDetail[item.key].length > 0) {
                tempProgress = tempProgress + nextProgress
            }
        })
        setProgress(tempProgress)
    }, [profileDetail])

    /**
     * @method RedirectToScreen
     * @description handling redirection
     * @param key target screen key
     */
    const RedirectToScreen = key => {
        switch (key) {
            case keys.languages:
                navigate(`/profileSetup/LanguagesDetail`, { state: { ...location.state } })
                break

            case keys.selectAnAvatar:
                navigate(`/profileSetup/AvatarDetail`, { state: { ...location.state } })
                break

            case keys.additionalInfos:
                navigate(`/profileSetup/AdditionalInfos`, { state: { ...location.state } })
                break
            default:
                navigate(`/profileSetup/addDetails/${key}`, { state: { ...location.state } })
        }
    }
    return (
        <Layout headerRequired={false}>
            <PageMetaTags title={'Profile Setup | Bunton'} />
            <Container maxWidth='xl'>
                <Box
                    sx={{
                        maxWidth: '500px',
                        m: 'auto',
                        width: {
                            xs: '90%',
                            sm: '70%',
                            md: '50%'
                        }
                    }}
                >
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            {progress < 100 ? (
                                <Box>
                                    <Typography
                                        variant='h1'
                                        className='pageHeading cyan-color'
                                        gutterBottom
                                        sx={{ mt: 3 }}
                                    >
                                        {TextStrings.newUserWelcome}
                                    </Typography>
                                    <Typography
                                        variant='h3'
                                        className='small-size-18px'
                                        gutterBottom
                                    >
                                        {TextStrings.startBy}
                                    </Typography>
                                </Box>
                            ) : (
                                <Typography
                                    variant='h1'
                                    className='pageHeading cyan-color'
                                    gutterBottom
                                    sx={{ mt: 3 }}
                                >
                                    {TextStrings.youAreReady}!
                                </Typography>
                            )}
                        </Box>

                        {progress && <LinearProgressBar progress={progress} />}

                        <Box sx={styles.buttonContainer}>
                            {BUTTON_DATA.map(item => {
                                if (profileDetail[item.key] && profileDetail[item.key].length > 0) {
                                    return (
                                        <Button
                                            label={item.label}
                                            color='secondary'
                                            variant='contained'
                                            size='large'
                                            onClick={e => e.preventDefault()}
                                            sx={styles.editDetailButton}
                                            startIcon={
                                                <DoneIcon style={{ color: variables.CYAN }} />
                                            }
                                        />
                                    )
                                } else {
                                    return (
                                        <Button
                                            label={item.label}
                                            color='secondary'
                                            variant='contained'
                                            size='large'
                                            onClick={() => {
                                                RedirectToScreen(item.key)
                                            }}
                                            sx={styles.addDetailButton}
                                            startIcon={<Icons src={item.icon} />}
                                        />
                                    )
                                }
                            })}
                        </Box>
                    </Box>
                    {progress < 100 ? (
                        <MuiButton
                            variant='text'
                            sx={styles.setUpLaterButton}
                            onClick={() => {
                                if (location.state && location.state.redirectto) {
                                    navigate(location.state.redirectto)
                                } else {
                                    navigate('/dashboard')
                                }
                            }}
                        >
                            {TextStrings.setUpLater}
                        </MuiButton>
                    ) : (
                        <Box
                            sx={{
                                my: '20px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center'
                            }}
                        >
                            <Box>
                                <Button
                                    label={'Go to dashboard'}
                                    onClick={() => {
                                        navigate('/dashboard')
                                    }}
                                    color='primary'
                                    variant='contained'
                                    size='large'
                                    type='submit'
                                    sx={styles.progressCompleteButton}
                                />
                            </Box>
                            <Box
                                sx={{
                                    mt: 3
                                }}
                            >
                                <Button
                                    label={'Go to your profile'}
                                    onClick={() => {
                                        navigate('/my-profile')
                                    }}
                                    color='primary'
                                    variant='contained'
                                    size='large'
                                    type='submit'
                                    sx={[styles.progressCompleteButton]}
                                />
                            </Box>
                            {location.state && location.state.redirectto ? (
                                <Box
                                    sx={{
                                        mt: 3
                                    }}
                                >
                                    <Button
                                        label={'Go to Job Listing'}
                                        onClick={() => {
                                            navigate(location.state.redirectto)
                                        }}
                                        color='primary'
                                        variant='contained'
                                        size='large'
                                        type='submit'
                                        sx={{
                                            ...styles.progressCompleteButton,
                                            minWidth: '240px'
                                        }}
                                    />
                                </Box>
                            ) : null}
                        </Box>
                    )}
                </Box>
            </Container>
        </Layout>
    )
}

export default ProfileSetupStepOne
