/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import {
    Box,
    createTheme,
    FormHelperText,
    Link as MuiLink,
    ThemeProvider,
    Typography
} from '@mui/material'
import { TextStrings } from '../../../constants'
import variables from '../../../settings/_variables.scss'
import BuntonLogo from '../../../assets/images/Logo.svg'
import OtpInput from 'react18-input-otp'
import { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { loginSuccess } from '../../../redux/actions/login'
import Cookies from 'universal-cookie'

const theme = createTheme({
    components: {
        MuiTypography: {
            styleOverrides: {
                h5: {
                    fontSize: '35px',
                    color: variables.CYAN,
                    fontWeight: '600',
                    fontFamily: variables.poppinsRegular
                }
            }
        }
    }
})

const RESENT_OTP_MUTATION = gql`
    mutation SendOtp($userId: ID!) {
        sendOtp(userId: $userId) {
            id
        }
    }
`

const VALIDATE_MUTATION = gql`
    mutation ValidateOtp($userId: ID!, $otp: String!, $callFrom: String) {
        validateOtp(userId: $userId, otp: $otp, callFrom: $callFrom) {
            accessToken
            client
            expiry
            id
            uid
            firstName
            lastName
            role
            name
            hasAnswered
        }
    }
`
const cookies = new Cookies()
const Otp = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const { registrationDetails } = useSelector(state => state.registration)
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const [otp, setOtp] = useState('')
    const [apiError, setApiError] = useState('')

    const [second, setSecond] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            if (second === 0) {
                clearInterval(interval)
            } else {
                setSecond(second - 1)
            }
        }, 1000)

        return () => {
            clearInterval(interval)
        }
    })

    const [resendOTPMutation, { resend_data }] = useMutation(RESENT_OTP_MUTATION, {
        onCompleted(resend_data) {
            
        },
        onError(error) {
            console.log(error, 'error')
            setApiError(error)
        }
    })

    const [validateMutation, { data }] = useMutation(VALIDATE_MUTATION, {
        onCompleted(data) {
            dispatch(loginSuccess(data))
            cookies.set('BUNTON_ACCESS_TOKEN', data.validateOtp.accessToken, { path: '/' })
            cookies.set('BUNTON_CLIENT_TOKEN', data.validateOtp.client, { path: '/' })
            cookies.set('BUNTON_EXPIRY', data.validateOtp.expiry, { path: '/' })
            cookies.set('BUNTON_UID', data.validateOtp.uid, { path: '/' })
            navigate('/profileSetup', {state: {...location.state}})
        },
        onError(error) {
            console.log(error, 'error')
            setApiError(error)
        }
    })
    const handleChange = enteredOtp => {
        setApiError('')
        setOtp(enteredOtp)
        if (enteredOtp.length === 5) {
            validateMutation({
                variables: {
                    userId: atob(params.key),
                    otp: enteredOtp,
                    callFrom: 'registration'
                }
            })
        }
    }

    const resendOTP = () => {
        setSecond(60)
        resendOTPMutation({
            variables: {
                userId: atob(params.key)
            }
        })
    }

    const webStyle = {
        width: '60px',
        height: '80px',
        margin: '10px',
        borderRadius: '30px',
        fontSize: '30px',
        fontFamily: variables.poppinsRegular,
        border: `3px solid ${apiError ? variables.ERROR : variables.CYAN}`
    }
    const mobileStyle = {
        width: '50px',
        height: '70px',
        margin: '5px',
        borderRadius: '25px',
        fontSize: '30px',
        fontFamily: variables.poppinsRegular,
        border: `3px solid ${apiError ? variables.ERROR : variables.CYAN}`
    }
    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    backgroundColor: variables.WHITE1,
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                <Box
                    component='img'
                    src={BuntonLogo}
                    sx={{
                        width: '240px',
                        height: '65px',
                        marginBottom: '52px',
                        marginTop: '50px'
                    }}
                />
                <Typography
                    variant='h5'
                    gutterBottom
                    sx={{
                        fontSize: '31px',
                        fontWeight: '600',
                        width: '230px',
                        textAlign: 'center',
                        marginBottom: '36px'
                    }}
                >
                    {TextStrings.registrationComplete}
                </Typography>
                <Typography
                    variant='subtitle1'
                    gutterBottom
                    sx={{ fontSize: '18px', width: '288px', textAlign: 'center' }}
                >
                    {TextStrings.checkInbox}
                </Typography>
                <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={5}
                    isInputNum={true}
                    separator={<span> </span>}
                    containerStyle={{ padding: '20px' }}
                    inputStyle={responsiveBreakpoint === 'mob' ? mobileStyle : webStyle}
                />

                {apiError && apiError?.message ? (
                    <FormHelperText 
                        id='my-helper-text'
                        error={true}
                    >
                        <strong>{apiError?.message}</strong>
                    </FormHelperText>
                ) : null}

                <MuiLink
                    component='button'
                    variant='body2'
                    sx={{
                        color: variables.BLUE,
                        fontSize: '18px',
                        fontFamily: variables.poppinsRegular
                    }}
                    disabled={second > 0}
                    onClick={() => {
                        resendOTP()
                    }}
                    style={second ? { pointerEvents: 'none', color: variables.LOADING } : null}
                >
                    {TextStrings.sendCodeAgain}
                </MuiLink>
            </Box>
        </ThemeProvider>
    )
}

export default Otp
