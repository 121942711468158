import { Box, CircularProgress, Tab, Tabs, Typography, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import EducationList from '../../../components/EducationList'
import ExperienceList from '../../../components/ExperienceList'
import { a11yProps, TabPanel } from '../../../utils/commonFunctions'
import AddIcon from '../../../assets/icons/NEEW.svg'
import { useSelector } from 'react-redux'
import variables from '../../../settings/_variables.scss'
import { Button } from '../../../components'
import { MUTATION_INVITE_CANDIDATE } from '../../../queries/userDetailsQuery'
import { useMutation } from '@apollo/client'
import StatusBox from '../../../components/StatusBox'
import Tooltip from '@mui/material/Tooltip'
import ManageUserSettings from '../../../components/molecules/ManageUserSettings'

const CandidateProfile = props => {
    const theme = useTheme()

    const params = useParams()
    const jobPostId = params.jobId ? params.jobId : 0
    const { isUserInvitedToApply, isUserApplied, candidate, isEdit, isAnonyomusProfile, currentUserRole, userId } = props
    const { profileDetail } = useSelector(state => state.profileDetail)
    const [isSuccess, setIsSuccess] = useState(false)
    const [isInvited, setIsInvited] = useState(isUserInvitedToApply)
    const [isApplied, setIsApplied] = useState(isUserApplied)

    const [inviteLoading, setInviteLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const location = useLocation()
    const state = location.state
    const [inviteCandidateMutation, { loading, data, error }] = useMutation(
        MUTATION_INVITE_CANDIDATE,
        {
            onCompleted(data) {
                setIsInvited(true)

                setInviteLoading(false)
                setSuccessMessage('Invitation sent!')
                setIsSuccess(true)
                setTimeout(() => {
                    setIsSuccess(false)
                    props.refetch()
                }, 3000)
            },
            onError(error) {
                console.log(error, 'error')
            }
        }
    )

    const educationDetails = isEdit
        ? profileDetail.edu
        : candidate && candidate.educationDetails
        ? candidate.educationDetails
        : {}

    const experienceDetails = isEdit
        ? profileDetail.exp
        : candidate && candidate.experienceDetails
        ? candidate.experienceDetails
        : {}

    const [index, setIndex] = useState(state && state.activeIndex ? state.activeIndex : 0)
    const handleIndexChange = (event, index) => {
        setIndex(index)
    }
    const handleChangeIndex = index => {
        setIndex(index)
    }
    return (
        <Box>
            <Box
                sx={{
                    display: 'flex'
                }}
            >
                <Tabs
                    value={index}
                    onChange={handleIndexChange}
                    textColor='inherit'
                    sx={{
                        width: '100%'
                    }}
                >
                    <Tab
                        label={<Typography variant='h3' className='strong'>Experience</Typography>}
                        disableFocusRipple={true}
                        disableRipple={true}
                        {...a11yProps(0)}
                        sx={{
                            paddingLeft: 0
                        }}
                    />
                    <Tab
                        sx={{
                            marginLeft: {
                                xs: '10px',
                                sm: '30px'
                            }
                        }}
                        label={<Typography variant='h3' className='strong'>Education</Typography>}
                        disableFocusRipple={true}
                        disableRipple={true}
                        {...a11yProps(1)}
                    />
                </Tabs>
                {isEdit ? (
                    <Box
                        sx={{
                            position: 'relative',
                            top: '20px'
                        }}
                    >
                        <Link
                            to={
                                index === 0
                                    ? '/add-new-experience/exp'
                                    : index === 1
                                    ? '/add-new-education'
                                    : '/'
                            }
                        >
                            <Tooltip title='Add'>
                                <Box
                                    component='img'
                                    sx={{
                                        width: '22x',
                                        height: '22px'
                                    }}
                                    src={AddIcon}
                                />
                            </Tooltip>
                        </Link>
                    </Box>
                ) : isAnonyomusProfile &&
                  !isInvited &&
                  location.pathname.includes('recommended-candidate') ? (
                    <Button
                        label={
                            inviteLoading ? (
                                <CircularProgress
                                    size={20}
                                    sx={{
                                        color: variables.BLUE
                                    }}
                                />
                            ) : (
                                'Invite'
                            )
                        }
                        disabled={inviteLoading}
                        color='secondary'
                        variant='contained'
                        // endIcon={<Icons src={endIcon} />}
                        size='large'
                        onClick={() => {
                            setInviteLoading(true)
                            inviteCandidateMutation({
                                variables: {
                                    id: candidate.id,
                                    jobId: jobPostId
                                }
                            })
                        }}
                        sx={{
                            fontWeight: '600',
                            padding: '8px 40px',
                            position: {
                                xs:'absolute',
                                sm: 'relative'
                            },
                            bottom: {xs: 16, sm: null},
                            right: {xs: 16, sm: null}
                        }}
                    />
                ) : (isAnonyomusProfile && isInvited) ||
                  (!isAnonyomusProfile &&
                      isInvited &&
                      !isApplied &&
                      location.pathname.includes('recommended-candidate')) ? (
                    <Button
                        label='Invited'
                        color='secondary'
                        variant='contained'
                        // endIcon={<Icons src={endIcon} />}
                        size='large'
                        sx={{
                            color: variables.WHITE,
                            fontWeight: '600',
                            padding: '8px 40px',
                            cursor: 'default',
                            position: {
                                xs:'absolute',
                                sm: 'relative'
                            },
                            bottom: {xs: 16, sm: null},
                            right: {xs: 16, sm: null}
                        }}
                    />
                ) : !isAnonyomusProfile &&
                  !isApplied &&
                  !isInvited &&
                  location.pathname.includes('recommended-candidate') ? (

                    <Button
                        label={
                            inviteLoading ? (
                                <CircularProgress
                                    size={20}
                                    sx={{
                                        color: variables.BLUE
                                    }}
                                />
                            ) : (
                                'Invite'
                            )
                        }
                        disabled={inviteLoading}
                        color='secondary'
                        variant='contained'
                        // endIcon={<Icons src={endIcon} />}
                        size='large'
                        onClick={() => {
                            setInviteLoading(true)
                            inviteCandidateMutation({
                                variables: {
                                    id: candidate.id,
                                    jobId: jobPostId
                                }
                            })
                        }}
                        sx={{
                            fontWeight: '600',
                            padding: '8px 40px',
                            position: {
                                xs:'absolute',
                                sm: 'relative'
                            },
                            bottom: {xs: 16, sm: null},
                            right: {xs: 16, sm: null}
                        }}
                    />
                ) : currentUserRole === 'admin' ? <ManageUserSettings candidateId={candidate.id} candidateUserId={userId}/>: null}
            </Box>
            <Box
                sx={{
                    marginTop: '30px'
                }}
            >
                <SwipeableViews
                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                    index={index}
                    onChangeIndex={handleChangeIndex}
                >
                    <TabPanel value={index} index={0} dir={theme.direction}>
                        <Box>
                            {experienceDetails && experienceDetails.length > 0 ? (
                                experienceDetails.map(experience => (
                                    <ExperienceList isEdit={isEdit} data={experience} />
                                ))
                            ) : (
                                <Box
                                    sx={{
                                        backgroundColor: variables.CYAN1,
                                        minHeight: '200px',
                                        padding: '24px',
                                        borderRadius: '30px',
                                        textAlign: 'center'
                                    }}
                                >
                                    No experience added
                                </Box>
                            )}
                        </Box>
                    </TabPanel>
                    <TabPanel value={index} index={1} dir={theme.direction}>
                        <Box>
                            {educationDetails && educationDetails.length > 0 ? (
                                educationDetails.map(education => (
                                    <EducationList isEdit={isEdit} data={education} />
                                ))
                            ) : (
                                <Box
                                    sx={{
                                        backgroundColor: variables.CYAN1,
                                        minHeight: '200px',
                                        padding: '24px',
                                        borderRadius: '30px',
                                        textAlign: 'center'
                                    }}
                                >
                                    No education added
                                </Box>
                            )}
                        </Box>
                    </TabPanel>
                </SwipeableViews>
            </Box>
            {isSuccess ? <StatusBox statusText={successMessage} /> : null}
        </Box>
    )
}

export default CandidateProfile
