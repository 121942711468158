import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useCookieConsentContext } from '@use-cookie-consent/react';
import React from 'react'
import Button from '../Button';
import variables from '../../settings/_variables.scss'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const CookieBanner = () => {
    const { consent, acceptAllCookies, declineAllCookies, acceptCookies } =
      useCookieConsentContext({
        defaultConsent: false
      });
    const theme = useTheme()
    const mob = useMediaQuery(theme.breakpoints.down('sm'))

    return Object.keys(consent).length > 0 && cookies.get('buntonCheck') === 'true' ? null : ( 
      <Box sx={{background: variables.BLUE,
        position: 'fixed',
        zIndex: '9',
        width: mob ? '77%' :  '450px',
        left: 'auto',
        right: '30px',
        bottom: '15px',
        borderRadius: '30px',
        padding: '15px',
        display: 'block'}}>
        <Box sx={{margin: 2}}>
            <Typography sx={{color: variables.WHITE}}>
                To provide the best experiences, we use technologies like cookies to store
            and/or access device information. Consenting to these technologies will
            allow us to process data such as browsing behavior or unique IDs on this
            site.
            </Typography>
        </Box>
        <Grid container spacing={3} sx={{p: 2}}>
            <Grid item md={6} sm={12} xs={12}>
                <Button label='Accept All' onClick={() => {
                  cookies.set('buntonCheck', true)  
                  acceptAllCookies()
                }} variant='cookieButtons'/>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
                <Button label='Essentials Only' onClick={() => {
                  cookies.set('buntonCheck', true)
                  acceptCookies({ necessary: true })
                }} variant='cookieButtons'/>
            </Grid>
        </Grid>
        {/* <button >Accept all</button>
        <button >
          Accept third-party
        </button>
        <button onClick={() => acceptCookies({ firstParty: true })}>
          Accept first-party
        </button>
        <button onClick={declineAllCookies}>Reject all</button> */}
      </Box>
    )
}

  export default CookieBanner