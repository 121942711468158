import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    CircularProgress,
    Typography
} from '@mui/material'
import React from 'react'
import variables from '../../../settings/_variables.scss'
import Industry from '../../../assets/icons/INDUSTRY.svg'
import Department from '../../../assets/icons/DEPARTMENT.svg'
import Experience from '../../../assets/icons/EXPERIENCE.svg'
import { Button } from '../../../components'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Icons from '../../../components/Icons'
import DownArrow from '../../../assets/icons/ARROW DOWN.svg'
import useDrag from '../../../utils/useDrag'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import endIcon from '../../../assets/icons/ARROW.svg'
import { experiencesList } from '../../../utils/constantData'
import { getAvatrURL } from '../../../utils/utilities'
import AvatarDefault from '../../../assets/images/avatar.png'
import { getStringFromArray, truncateDetails } from '../../../utils/commonFunctions'
import LoadMoreLink from '../../../components/elements/LoadMoreLink'
import IconTextBox from '../../../components/elements/IconTextBox'
import { useSwipe } from '../../../utils/useSwipe'

const DashboardJobsCandidates = props => {
    const { jobsData, pageInfo, fetchMore, listLoader, setListLoader } = props
    const [expanded, setExpanded] = React.useState(
        jobsData && jobsData.length > 0 ? jobsData[0].id : false
    )
    const { dragStart, dragStop, dragMove } = useDrag()
    const { onTouchEnd, onTouchMove, onTouchStart } = useSwipe();
    const { responsiveBreakpoint } = useSelector(state => state.initial)

    const handleDrag =
        ({ scrollContainer }) =>
        ev =>
            dragMove(ev, posDiff => {
                if (scrollContainer.current) {
                    scrollContainer.current.scrollLeft += posDiff
                }
            })
    const { isUserAuthenticated } = useSelector(state => state.login)

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }
    return (
        <>
            {jobsData.length > 0 ? (
                jobsData.map(job => (
                    <Accordion
                        sx={{
                            '&.MuiAccordion-root:before': {
                                backgroundColor: variables.BLUE
                            },
                            boxShadow: 'none',
                            backgroundColor: variables.WHITE1
                        }}
                        expanded={expanded === job.id}
                        onChange={handleChange(job.id)}
                    >
                        <AccordionSummary expandIcon={<Icons src={DownArrow} />}>
                            <Typography
                                variant='h5'
                                className='small-size'
                                sx={{
                                    width: '80%',
                                    flexShrink: 0
                                }}
                            >
                                {job.jobTitle}
                            </Typography>
                            <Typography
                                variant='h5'
                                className='small-size'
                                sx={{
                                    ml: responsiveBreakpoint === 'mob' ? '-37px' : null
                                }}
                            >
                                {job.candidates.length > 0
                                    ? `${job.candidates.length} ${
                                          job.candidates.length === 1 ? 'applicant' : 'applicants'
                                      }`
                                    : ''}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ padding: '0px' }}>
                            {job.candidates.length > 0 ? (
                                <Box component='div' onMouseLeave={dragStop}>
                                    <ScrollMenu
                                        onMouseDown={() => dragStart}
                                        onMouseUp={() => dragStop}
                                        onMouseMove={handleDrag}
                                        onTouchEnd={onTouchEnd}
                                        onTouchMove={onTouchMove}
                                        onTouchStart={onTouchStart}
                                    >
                                        {job.candidates.map((elem, index) => {
                                            return (
                                                <Box
                                                    sx={{
                                                        backgroundColor: variables.CYAN1,
                                                        width: '300px',
                                                        borderRadius: {
                                                            xs: '30px',
                                                            sm: '50px'
                                                        },
                                                        m: 2,
                                                        p: 2
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            textAlign: 'left'
                                                        }}
                                                    >
                                                        <Avatar
                                                            src={
                                                                elem.user && elem.user.profileImage
                                                                    ? getAvatrURL(
                                                                          elem.user.profileImage
                                                                      )
                                                                    : AvatarDefault
                                                            }
                                                            sx={{
                                                                height: {
                                                                    xs: '70px',
                                                                    md: '100px'
                                                                },
                                                                width: {
                                                                    xs: '70px',
                                                                    md: '100px'
                                                                }
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <Box
                                                            sx={{
                                                                mt: 2
                                                            }}
                                                        >
                                                            <Typography
                                                                variant='h3'
                                                                className='small-size-18px strong'
                                                            >
                                                                {elem.name}
                                                            </Typography>
                                                            <Box
                                                                sx={{
                                                                    mt: 2
                                                                }}
                                                            >
                                                                <Box>
                                                                    <IconTextBox
                                                                        iconSrc={Department}
                                                                        text={truncateDetails(
                                                                            getStringFromArray(
                                                                                elem.departments
                                                                            ),
                                                                            40
                                                                        )}
                                                                    />
                                                                </Box>

                                                                <Box
                                                                    sx={{
                                                                        mt: 1
                                                                    }}
                                                                >
                                                                    <IconTextBox
                                                                        iconSrc={Industry}
                                                                        text={truncateDetails(
                                                                            getStringFromArray(
                                                                                elem.industries
                                                                            ),
                                                                            40
                                                                        )}
                                                                    />
                                                                </Box>

                                                                <Box
                                                                    sx={{
                                                                        mt: 1
                                                                    }}
                                                                >
                                                                    <IconTextBox
                                                                        iconSrc={Experience}
                                                                        text={
                                                                            experiencesList.find(
                                                                                obj =>
                                                                                    obj.value ===
                                                                                    elem.experience
                                                                            )?.label
                                                                        }
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                textAlign: 'center',
                                                                my: 2
                                                            }}
                                                        >
                                                            <Button
                                                                label='More info'
                                                                color='primary'
                                                                variant='contained'
                                                                endIcon={<Icons src={endIcon} />}
                                                                size='large'
                                                                component={Link}
                                                                to={
                                                                    '/candidate-details/' +
                                                                    elem.userId
                                                                }
                                                                sx={{
                                                                    px: 9
                                                                }}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )
                                        })}
                                    </ScrollMenu>
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        backgroundColor: variables.CYAN1,
                                        borderRadius: '40px',
                                        padding: '20px',
                                        height: '250px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex'
                                    }}
                                >
                                    <Typography variant='h3'>
                                        No candidate applied for this job.
                                    </Typography>
                                </Box>
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))
            ) : (
                <Box
                    sx={{
                        minHeight: '250px',
                        borderRadius: '40px',
                        paddingTop: '15px',
                        backgroundColor: variables.CYAN1
                    }}
                >
                    <Box
                        sx={{
                            margin: 'auto',
                            width: '300px'
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontSize: '20px',
                                color: variables.BLUE
                            }}
                        >
                            There are no Applicants at the moment.
                            {/* No Jobs added, you can start adding new jobs by clicking{' '} */}
                            {/* <Link to='/add-job'>here</Link> */}
                        </Typography>
                    </Box>
                </Box>
            )}
            {isUserAuthenticated && pageInfo.hasNextPage ? (
                listLoader ? (
                    <Box
                        sx={{
                            textAlign: 'center'
                        }}
                    >
                        <CircularProgress
                            size={20}
                            sx={{
                                color: variables.BLUE
                            }}
                        />
                    </Box>
                ) : (
                    <LoadMoreLink
                        onClick={() => {
                            if (pageInfo.hasNextPage) {
                                setListLoader(true)
                                fetchMore({
                                    variables: {
                                        afterCursor: pageInfo.endCursor ? pageInfo.endCursor : ''
                                    }
                                })
                            }
                        }}
                    />
                )
            ) : null}
        </>
    )
}

export default DashboardJobsCandidates
