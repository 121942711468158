/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { useState } from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { Button } from '../../../components'
import Icons from '../../../components/Icons'
import { keys } from '../../../constants'
import EDUCATION from '../../../assets/icons/EDUCATION.svg'
import EDITICON from '../../../assets/icons/EDIT.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { convertDateFromUTC, DATE_FORMATS } from '../../../utils/utilities'
import { useDispatch, useSelector } from 'react-redux'
import {
    addProfileDetailFailure,
    deleteProfileDetailSuccess
} from '../../../redux/actions/profileDetail'
import { MANAGE_EXPERIENCE_MUTATION } from '../../../gql/queries'
import { useMutation } from '@apollo/client'
import FactoryIcon from '@mui/icons-material/Factory'
import { styles } from './styles'
import { StyledIconBox } from '../../../base/commonStyles'
import variables from '../../../settings/_variables.scss'

import DeleteIcon from '../../../assets/icons/DELETE.svg'
import PageMetaTags from '../../../base/PageMetaTags'
import DeleteEntityBox from '../../../components/DeleteEntityBox'

const ExperienceRow = props => {
    const { elem } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let { key } = useParams()
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const [deleteExperience, setDeleteExperienceModal] = useState(false)

    const [manageExperienceMutation, { expData, expError, expLoading }] = useMutation(
        MANAGE_EXPERIENCE_MUTATION,
        {
            onCompleted(data) {
                setDeleteExperienceModal(false)
                let formData = []
                formData['key'] = keys.workExperience
                formData['data'] = {
                    id: data.manageExperience.id,
                    actionType: data.manageExperience.actionType
                }
                dispatch(deleteProfileDetailSuccess(formData))
            },
            onError(error) {
                setDeleteExperienceModal(false)
                dispatch(addProfileDetailFailure(error.message))
            }
        }
    )

    const handleDeleteClose = () => {
        setDeleteExperienceModal(false)
    }

    const deleteExperienceDetail = id => {
        let formData = []
        formData['key'] = keys.workExperience
        formData['data'] = {
            id: id,
            actionType: 'delete'
        }
        manageExperienceMutation(id)
        // dispatch(deleteProfileDetailSuccess(formData))
        // navigate(-1)
    }

    const renderExperienceIcon = id => {
        return (
            <Box sx={styles.iconContainer}>
                <PageMetaTags title={'Profile Setup | Bunton'} />
                {responsiveBreakpoint === 'mob' ? (
                    <>
                        <Button
                            label={
                                <Box component='span'>
                                    <Typography
                                        component='span'
                                        sx={{
                                            fontSize: '17px'
                                        }}
                                    >
                                        Edit
                                    </Typography>
                                </Box>
                            }
                            onClick={() => {
                                navigate(`/profileSetup/ExperienceDetail/${id}`)
                            }}
                            color='primary'
                            variant='contained'
                            size='large'
                            sx={{
                                padding: '13px 30px',
                                fontSize: '14px',
                                fontWeight: '600'
                            }}
                        />
                        <Button
                            label={
                                <Box component='span'>
                                    <Typography
                                        component='span'
                                        sx={{
                                            fontSize: '17px'
                                        }}
                                    >
                                        Delete
                                    </Typography>
                                </Box>
                            }
                            onClick={() => {
                                setDeleteExperienceModal(true)
                                // deleteExperienceDetail(id)
                            }}
                            color='secondary'
                            variant='contained'
                            size='large'
                            sx={{
                                padding: '13px 30px',
                                fontSize: '14px',
                                fontWeight: '600',
                                ml: '10px'
                            }}
                        />
                    </>
                ) : (
                    <>
                        <Tooltip title='Edit'>
                            <StyledIconBox
                                sx={{
                                    background: '#fff',
                                    marginLeft: '15px'
                                }}
                                onClick={() => {
                                    navigate(`/profileSetup/ExperienceDetail/${id}`)
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        top: '5px',
                                        color: variables.BLUE
                                    }}
                                >
                                    <Icons
                                        src={EDITICON}
                                        sx={{
                                            height: '30px',
                                            width: '30px'
                                        }}
                                    />
                                </Box>
                            </StyledIconBox>
                        </Tooltip>
                        <Tooltip title='Delete'>
                            <StyledIconBox
                                sx={{
                                    background: `${variables.ORANGE}`,
                                    marginLeft: '15px'
                                }}
                                onClick={() => {
                                    setDeleteExperienceModal(true)
                                    // deleteExperienceDetail(id)
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        top: '25%',
                                        color: '#ffffff'
                                    }}
                                >
                                    <Box component='img' src={DeleteIcon} sx={{ height: '20px' }} />
                                </Box>
                            </StyledIconBox>
                        </Tooltip>
                    </>
                )}
            </Box>
        )
    }

    let startDateResp = convertDateFromUTC(elem.startDate, DATE_FORMATS.YEAR_MONTH_NUMBER_FORMAT)
    let endDateResp = convertDateFromUTC(elem.endDate, DATE_FORMATS.YEAR_MONTH_NUMBER_FORMAT)
    let startDateArray = startDateResp.split('-')
    let endDateArray = endDateResp == 'Invalid date' ? 'AN' : endDateResp.split('-')
    let endDate = elem.isCurrentJob == true ? 'Present' : `${endDateArray[1]}.${endDateArray[0]}`

    return (
        <Box sx={styles.listWrapper}>
            <Box sx={styles.listContainer}>
                <Icons src={EDUCATION} />
                <Box sx={styles.titleContainerExperience}>
                    <Box sx={styles.experienceDescContainer}>
                        <Typography gutterBottom sx={styles.detailsTitle}>
                            {elem.companyName}
                        </Typography>
                        <Typography gutterBottom sx={styles.detailsSubTitle}>
                            <strong>{elem.jobTitle}</strong>
                        </Typography>
                        <Typography gutterBottom sx={styles.date}>
                            {`${startDateArray[1]}.${startDateArray[0]} – ${endDate}`}
                        </Typography>
                    </Box>
                    <Box sx={styles.industryContainer}>
                        <FactoryIcon />
                        <Typography gutterBottom sx={styles.industryTitle}>
                            {elem.industry.name}
                        </Typography>
                    </Box>
                </Box>
                {(responsiveBreakpoint === 'web' || responsiveBreakpoint === 'tab') &&
                    renderExperienceIcon(elem.id)}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    pb: '20px'
                }}
            >
                {responsiveBreakpoint === 'mob' && renderExperienceIcon(elem.id)}
            </Box>
            <DeleteEntityBox
                open={deleteExperience}
                deleteMutation={deleteExperienceDetail}
                entityId={elem.id}
                deleteLoading={expLoading}
                closeDelete={handleDeleteClose}
            />
        </Box>
    )
}

export default ExperienceRow
