/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { useState } from 'react'
import { Box, Tooltip, Typography } from '@mui/material'
import { Button } from '../../../components'
import Icons from '../../../components/Icons'
import { keys } from '../../../constants'
import EDUCATION from '../../../assets/icons/EDUCATION.svg'
import EDITICON from '../../../assets/icons/EDIT.svg'
import { useNavigate, useParams } from 'react-router-dom'
import {
    addProfileDetailFailure,
    deleteProfileDetailSuccess
} from '../../../redux/actions/profileDetail'
import { MANAGE_EDUCATION_MUTATION } from '../../../gql/queries'
import { useMutation } from '@apollo/client'
import { styles } from './styles'
import { StyledIconBox } from '../../../base/commonStyles'
import variables from '../../../settings/_variables.scss'

import DeleteIcon from '../../../assets/icons/DELETE.svg'
import PageMetaTags from '../../../base/PageMetaTags'
import DeleteEntityBox from '../../../components/DeleteEntityBox'
import { useDispatch, useSelector } from 'react-redux'

const EducationRow = props => {
    const { elem } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let { key } = useParams()
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const [deleteEducation, setDeleteEducationModal] = useState(false)

    const [manageEducationMutation, { data, error, loading, reset }] = useMutation(
        MANAGE_EDUCATION_MUTATION,
        {
            onCompleted(data) {
                setDeleteEducationModal(false)
                let formData = []
                formData['key'] = keys.education
                formData['data'] = {
                    id: data.manageEducation.id,
                    actionType: data.manageEducation.actionType
                }
                dispatch(deleteProfileDetailSuccess(formData))
            },
            onError(error) {
                setDeleteEducationModal(false)
                dispatch(addProfileDetailFailure(error.message))
            }
        }
    )

    const handleDeleteClose = () => {
        setDeleteEducationModal(false)
    }

    const deleteEducationDetail = id => {
        let formData = []
        formData['key'] = keys.education
        formData['data'] = id
        manageEducationMutation(id)
        // dispatch(deleteProfileDetailSuccess(formData))
        // navigate(-1)
    }

    const renderEducationIcon = id => {
        return (
            <Box sx={styles.iconContainer}>
                {responsiveBreakpoint === 'mob' ? (
                    <>
                        <Button
                            label={
                                <Box component='span'>
                                    <Typography
                                        component='span'
                                        sx={{
                                            fontSize: '17px'
                                        }}
                                    >
                                        Edit
                                    </Typography>
                                </Box>
                            }
                            onClick={() => {
                                navigate(`/profileSetup/EducationDetail/${id}`)
                            }}
                            color='primary'
                            variant='contained'
                            size='large'
                            sx={{
                                padding: '13px 30px',
                                fontSize: '14px',
                                fontWeight: '600'
                            }}
                        />
                        <Button
                            label={
                                <Box component='span'>
                                    <Typography
                                        component='span'
                                        sx={{
                                            fontSize: '17px'
                                        }}
                                    >
                                        Delete
                                    </Typography>
                                </Box>
                            }
                            onClick={() => {
                                setDeleteEducationModal(true)
                            }}
                            color='secondary'
                            variant='contained'
                            size='large'
                            sx={{
                                padding: '13px 30px',
                                fontSize: '14px',
                                fontWeight: '600',
                                ml: '10px'
                            }}
                        />
                    </>
                ) : (
                    <>
                        <Tooltip title='Edit'>
                            <StyledIconBox
                                sx={{
                                    background: '#fff',
                                    marginLeft: '15px'
                                }}
                                onClick={() => {
                                    navigate(`/profileSetup/EducationDetail/${id}`)
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        top: '5px',
                                        color: variables.BLUE
                                    }}
                                >
                                    <Icons
                                        src={EDITICON}
                                        sx={{
                                            height: '30px',
                                            width: '30px'
                                        }}
                                    />
                                </Box>
                            </StyledIconBox>
                        </Tooltip>
                        <Tooltip title='Delete'>
                            <StyledIconBox
                                sx={{
                                    background: `${variables.ORANGE}`,
                                    marginLeft: '15px'
                                }}
                                onClick={() => {
                                    setDeleteEducationModal(true)
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        top: '25%',
                                        color: '#ffffff'
                                    }}
                                >
                                    <Box component='img' src={DeleteIcon} sx={{ height: '20px' }} />
                                </Box>
                            </StyledIconBox>
                        </Tooltip>
                    </>
                )}
            </Box>
        )
    }

    return (
        <Box sx={styles.listWrapper}>
            <Box sx={styles.listContainer}>
                <Icons src={EDUCATION} />
                <Box sx={styles.titleContainer}>
                    <Typography gutterBottom sx={styles.detailsTitle}>
                        {elem.certificateDegreeName}
                    </Typography>
                    <Typography gutterBottom sx={styles.detailsSubTitle}>
                        {elem.major}
                    </Typography>
                    <Typography gutterBottom sx={styles.date}>
                        {elem.yearOfCompletion}
                    </Typography>
                </Box>
                {(responsiveBreakpoint === 'web' || responsiveBreakpoint === 'tab') &&
                    renderEducationIcon(elem.id)}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    pb: '20px'
                }}
            >
                {responsiveBreakpoint === 'mob' && renderEducationIcon(elem.id)}
            </Box>
            <DeleteEntityBox
                open={deleteEducation}
                deleteMutation={deleteEducationDetail}
                entityId={elem.id}
                deleteLoading={loading}
                closeDelete={handleDeleteClose}
            />
        </Box>
    )
}

export default EducationRow
