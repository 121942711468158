import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'
import React, { useState } from 'react'

import variables from '../../settings/_variables.scss'
import UserSettingsIcon from '../../assets/icons/user-settings.svg'
import UserNotificationModal from '../organisams/UserNotificationModal'

const ManageUserSettings = props => {
    const {candidateUserId} = props
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [openNotificationModal, setOpenNotificationModal] = useState(false)
    const [deleteProfileModal, setDeleteProfileModal] = useState(false)
    const open = Boolean(anchorEl)
    const handleMoreOptionClick = event => {
        setAnchorEl(event.currentTarget)
    }
    const handleMoreOptionClose = () => {
        setAnchorEl(null)
    }
    return <Box sx={{ textAlign: 'left' }}>
        <Button
            id='more-options-button'
            aria-controls={open ? 'more-options-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            variant='contained'
            color='secondary'
            sx={{
                p: '10px 30px',
                background: 'transparent',
                '&:hover': {
                    background: 'transparent'
                }
            }}
            disableElevation
            onClick={handleMoreOptionClick}
            label=''
            // endIcon={<KeyboardArrowDownIcon />}
        >
            <Box
                component='img'
                sx={{
                    width: '22x',
                    height: '22px'
                }}
                src={UserSettingsIcon}
            />
        </Button>
        <Menu
            id='more-options-menu'
            MenuListProps={{
                'aria-labelledby': 'more-options-button'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleMoreOptionClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    background: variables.CYAN,
                    width: '200px',
                    maxWidth: '300px'
                }
            }}
        >
            <MenuItem
                onClick={() => {
                    setOpenNotificationModal(true)
                    handleMoreOptionClose()
                }}
                disableRipple
                sx={{
                    p: 2,
                    textAlign: 'center'
                }}
            >
                <Typography
                    variant='h5'
                    className='small-size-strong'
                    sx={{ width: '100%' }}
                >
                    Notifications
                </Typography>
            </MenuItem>
            {/* <MenuItem
                onClick={() => {
                    setDeleteProfileModal(true)
                    handleMoreOptionClose()
                }}
                disableRipple
                sx={{
                    p: 2,
                    textAlign: 'center'
                }}
            >
                <Typography
                    variant='h5'
                    className='small-size-strong'
                    sx={{ width: '100%' }}
                >
                    Delete Profile
                </Typography>
            </MenuItem> */}
        </Menu>
        {
            openNotificationModal && <UserNotificationModal open={openNotificationModal} onClose={() => setOpenNotificationModal(false)} candidateUserId={candidateUserId}/>
        }
    </Box>
}

export default ManageUserSettings