import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ProfileCompletionLoader from '../../components/ProfileCompletionLoader'
import { BUTTON_DATA } from '../../constants'
import { Link } from 'react-router-dom'
import JobSearch from '../../components/JobSearch'

const DashboardHeaderSection = props => {
    const { employedDashboard } = props
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const initialProgress = 20
    const nextProgress = 16
    const [progress, setProgress] = useState(initialProgress)
    const { profileDetail } = useSelector(state => state.profileDetail)
    useEffect(() => {
        let tempProgress = initialProgress
        BUTTON_DATA?.map(item => {
            if (profileDetail[item.key] !== null && profileDetail[item.key].length > 0) {
                tempProgress = tempProgress + nextProgress
            }
        })
        setProgress(tempProgress)
    }, [profileDetail])

    return (
        <>
            <Grid container columnSpacing={{xs: 0, sm: 4}} rowSpacing={{xs: 4, sm: 0}} direction={{xs: 'column-reverse', sm: 'row'}}>
                <Grid item 
                    xs={12} 
                    sm={!employedDashboard && progress < 100 ? 7 : 12} 
                    md={!employedDashboard && progress < 100 ? 8 : 12} 
                    lg={!employedDashboard && progress < 100 ? 9 : 12} 
                    xl={!employedDashboard && progress < 100 ? 9 : 12}
                >
                    <Typography variant='h1' className='pageHeading'>Dashboard</Typography>
                </Grid>
                {!employedDashboard ? 
                    progress < 100 ? 
                        <Grid item xs={12} sm={5} md={4} lg={3} xl={3}>
                            <Link to={'/my-profile'}>
                                <ProfileCompletionLoader progress={progress} link='/my-profile' />
                            </Link>
                        </Grid>
                    : null
                : null}                
            </Grid>
            
            {!employedDashboard && responsiveBreakpoint === 'mob' ? (
                <Box>
                    <JobSearch />
                </Box>
            ) : null}
        </>
    )
}

export default DashboardHeaderSection
