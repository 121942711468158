import React from 'react'
import AboutUs from '../views/AboutUs'
import Dashboard from '../views/Dashboard'
import Login from '../views/Login'
import Companies from '../views/AdminPortal/Companies'
import AddCompany from '../views/AdminPortal/AddCompany'

import Registration from '../views/Registration'
import Otp from '../views/Registration/Otp'
import ProfileSetupStepOne from '../views/ProfileSetup/ProfileSetupStepOne'
import ProfileSetupStepTwo from '../views/ProfileSetup/ProfileSetupStepTwo'
import EducationDetail from '../views/ProfileSetup/EducationDetail'
import LanguagesDetail from '../views/ProfileSetup/LanguagesDetail'
import AvatarDetail from '../views/ProfileSetup/AvatarDetail'
import SuggestedJobs from '../views/SuggestedJobs'
import AdminPortal from '../views/AdminPortal'
import PublicCompanyList from '../views/PublicCompanyList'
import JobListing from '../views/JobListing'
import ExperienceDetail from '../views/ProfileSetup/ExperienceDetail'
import UserProfile from '../views/UserProfile'
import Jobs from '../views/AdminPortal/Jobs'
import JobDetails from '../views/JobDetails'

import PrivacyPolicy from '../views/pages/PrivacyPolicy'
import CorporateInformation from '../views/pages/CorporateInformation'
import Imprint from '../views/pages/Imprint'
import ErrorPages from '../views/ErrorPages'
import AddJob from '../views/AdminPortal/AddJob'
import ContactUs from '../views/ContactUs'
import EditProfile from '../views/UserProfile/EditProfile'
import EmployerAddJob from '../views/AdminPortal/AddJob/EmployerAddJob'
import EmployerAddCompany from '../views/AdminPortal/AddCompany/EmployerAddCompany'
import AppliedCandidate from '../views/AppliedCandidate'
import ChangePassword from '../pages/ChangePassword'
import AdditionalInfos from '../views/ProfileSetup/AdditionalInfos'
import TermsAndConditions from '../views/TermsAndConditions'
import Users from '../pages/Users'
import HomePage from '../pages/HomePage'
import AdminSettings from '../pages/AdminSettings'
import MarketingPdf from '../pages/MarketingPdf'
import Logout from '../pages/Logout'
import ClientContract from '../pages/ClientContract'
import CandidateHomePage from '../pages/CandidateHomePage'
import ValidateOtp from '../pages/ValidateOtp'
import ForgotPassword from '../pages/ForgotPassword'
import ResetPassword from '../pages/ResetPassword'
import UserSettings from '../pages/UserSettings'
import UnsubscribePage from '../pages/UnsubscribePage'
import CompanyProfilePage from '../pages/CompanyProfilePage'
import ContactUsRequest from '../pages/ContactUsRequest'
// import UploadFile from '../components/UploadFile'

export const publicRoutes = [
    {
        path: '/',
        component: () => <HomePage />,
        element: <HomePage />,
        exact: true
    },
    {
        path: '/candidate-homepage',
        component: () => <CandidateHomePage />,
        element: <CandidateHomePage />,
        exact: true
    },
    {
        path: '/why-bunton',
        component: () => <MarketingPdf />,
        element: <MarketingPdf />,
        exact: true
    },
    {
        path: '/anzeigenvertrag',
        component: () => <ClientContract />,
        element: <ClientContract />,
        exact: true
    },
    {
        path: '/about',
        component: () => <AboutUs />,
        element: <AboutUs />,
        exact: true
    },

    {
        path: '/contact-us',
        component: () => <ContactUs />,
        element: <ContactUs />,
        exact: true
    },

    //   {
    //     path: '/privacy-policy',
    //     component: () => <PrivacyPolicyEnglish />,
    //     exact: true
    //   },
    //   {
    //     path: '/terms-of-use',
    //     component: () => <TermsOfUseEnglish />,
    //     exact: true
    //   },
    {
        path: '/login',
        component: () => <Login />,
        element: <Login />,
        exact: true
    },
    {
        path: '/forgot-password',
        component: () => <ForgotPassword />,
        element: <ForgotPassword />,
        exact: true
    },
    {
        path: '/validate-email/:key',
        component: () => <ValidateOtp />,
        element: <ValidateOtp />,
        exact: true
    },
    {
        path: '/reset-password/:key',
        component: () => <ResetPassword />,
        element: <ResetPassword />,
        exact: true
    },

    {
        path: '/registration',
        component: () => <Registration />,
        element: <Registration />,
        exact: true
    },
    {
        path: '/otp/:key',
        component: () => <Otp />,
        element: <Otp />,
        exact: true
    },
    {
        path: '/companies',
        component: () => <PublicCompanyList />,
        element: <PublicCompanyList listAccess='all' />,
        exact: true
    },
    {
        path: '/job-list',
        component: () => <JobListing />,
        element: <JobListing listAccess='all' />,
        exact: true
    },

    {
        path: '/privacy-policy',
        component: () => <PrivacyPolicy />,
        element: <PrivacyPolicy />,
        exact: true
    },

    {
        path: '/corporate-information',
        component: () => <CorporateInformation />,
        element: <CorporateInformation />,
        exact: true
    },

    {
        path: '/imprint',
        component: () => <Imprint />,
        element: <Imprint />,
        exact: true
    },
    {
        path: '/terms-and-conditions',
        component: () => <TermsAndConditions />,
        element: <TermsAndConditions />,
        exact: true
    },
    {
        path: '/job-details/:id',
        component: () => <JobDetails />,
        element: <JobDetails listAccess='all' />,
        exact: true
    },
    {
        path: '/company-details/:slug/:id',
        component: () => <CompanyProfilePage />,
        element: <CompanyProfilePage />,
        exact: true
    },
    // {
    //     path: '/company-details/:id',
    //     component: () => <UserProfile />,
    //     element: <UserProfile />,
    //     exact: true
    // },
    {
        path: '/unsubscribe/:key',
        component: () => <UnsubscribePage />,
        element: <UnsubscribePage />,
        exact: true
    },
    {
        path: '/403',
        component: () => <>Error</>,
        element: <ErrorPages errorCode={403} />,
        exact: true
    },
    // {
    //     path: '/upload',
    //     component: () => <>Error</>,
    //     element: <UploadFile />,
    //     exact: true
    // },
    {
        path: '/404',
        component: () => <ErrorPages />,
        element: <ErrorPages errorCode={404} />,
        exact: true
    },
    {
        path: '/logout',
        component: () => <Logout />,
        element: <Logout />,
        exact: true
    },
    {
        path: '*',
        component: () => <ErrorPages />,
        element: <ErrorPages errorCode={404} />
    }
]

export const privateRoutes = [
    {
        path: '/logout',
        component: () => <Logout />,
        element: <Logout />,
        exact: true,
        roles: ['user', 'employer', 'admin']
    },
    {
        path: '/settings',
        component: () => <UserSettings />,
        element: <UserSettings />,
        exact: true,
        roles: ['user', 'employer', 'admin']
    },
    {
        path: '/unsubscribe/:key',
        component: () => <UnsubscribePage />,
        element: <UnsubscribePage />,
        exact: true,
        roles: ['user']
    },
    {
        path: '/dashboard',
        component: () => <Dashboard />,
        element: <Dashboard />,
        exact: true,
        roles: ['user', 'employer']
    },
    {
        path: '/about',
        component: () => <AboutUs />,
        element: <AboutUs />,
        exact: true,
        roles: ['user', 'employer', 'admin']
    },

    {
        path: '/contact-us',
        component: () => <ContactUs />,
        element: <ContactUs />,
        exact: true,
        roles: ['user', 'employer', 'admin']
    },
    {
        path: '/privacy-policy',
        component: () => <PrivacyPolicy />,
        element: <PrivacyPolicy />,
        exact: true,
        roles: ['user', 'employer', 'admin']
    },

    {
        path: '/corporate-information',
        component: () => <CorporateInformation />,
        element: <CorporateInformation />,
        exact: true,
        roles: ['user', 'employer', 'admin']
    },
    {
        path: '/why-bunton',
        component: () => <MarketingPdf />,
        element: <MarketingPdf />,
        exact: true,
        roles: ['user', 'employer', 'admin']
    },
    {
        path: '/anzeigenvertrag',
        component: () => <ClientContract />,
        element: <ClientContract />,
        exact: true,
        roles: ['user', 'employer', 'admin']
    },
    {
        path: '/imprint',
        component: () => <Imprint />,
        element: <Imprint />,
        exact: true,
        roles: ['user', 'employer', 'admin']
    },
    {
        path: '/terms-and-conditions',
        component: () => <TermsAndConditions />,
        element: <TermsAndConditions />,
        exact: true,
        roles: ['user', 'employer', 'admin']
    },
    {
        path: '/change-password/:key',
        component: () => <ChangePassword />,
        element: <ChangePassword />,
        exact: true,
        roles: ['user', 'employer', 'admin']
    },
    {
        path: '/my-profile',
        component: () => <UserProfile />,
        element: <UserProfile />,
        exact: true,
        roles: ['user', 'employer']
    },
    {
        path: '/edit-profile',
        component: () => <EditProfile />,
        element: <EditProfile />,
        exact: true,
        roles: ['user', 'employer']
    },
    {
        path: '/user-profile/:useId',
        component: () => <UserProfile />,
        element: <UserProfile />,
        exact: true,
        roles: ['user', 'employer', 'admin']
    },
    {
        path: '/admin-portal',
        component: () => <AdminPortal />,
        element: <AdminPortal />,
        exact: true,
        roles: ['admin']
    },
    {
        path: '/admin-portal/job-list',
        component: () => <Jobs />,
        element: <Jobs />,
        exact: true,
        roles: ['admin']
    },
    {
        path: '/admin-portal/companies',
        component: () => <Companies />,
        element: <Companies />,
        exact: true,
        roles: ['admin']
    },

    {
        path: '/admin-portal/add-company',
        component: () => <AddCompany />,
        element: <AddCompany />,
        exact: true,
        roles: ['admin']
    },
    {
        path: '/admin-portal/edit-company/:companyId',
        component: () => <AddCompany />,
        element: <AddCompany />,
        exact: true,
        roles: ['admin']
    },
    {
        path: '/admin-portal/users',
        component: () => <Users />,
        element: <Users />,
        exact: true,
        roles: ['admin']
    },
    {
        path: '/admin-portal/settings',
        component: () => <AdminSettings />,
        element: <AdminSettings />,
        exact: true,
        roles: ['admin']
    },
    {
        path: 'edit-company/:companyId',
        component: () => <EmployerAddCompany />,
        element: <EmployerAddCompany />,
        exact: true,
        roles: ['employer']
    },
    {
        path: '/companies',
        component: () => <PublicCompanyList />,
        element: <PublicCompanyList listAccess='all' />,
        exact: true,
        roles: ['user', 'admin']
    },
    {
        path: '/company-details/:slug/:id',
        component: () => <CompanyProfilePage />,
        element: <CompanyProfilePage />,
        exact: true,
        roles: ['user', 'admin', 'employer']
    },
    // {
    //     path: '/company-details/:id',
    //     component: () => <UserProfile />,
    //     element: <UserProfile />,
    //     exact: true,
    //     roles: ['user', 'admin', 'employer']
    // },
    {
        path: '/admin-portal/add-job/:companyId?',
        component: () => <AddJob />,
        element: <AddJob />,
        exact: true,
        roles: ['admin']
    },
    {
        path: '/admin-portal/edit-job/:jobId',
        component: () => <AddJob />,
        element: <AddJob />,
        exact: true,
        roles: ['admin']
    },
    {
        path: '/admin-portal/contact-request',
        component: () => <ContactUsRequest />,
        element: <ContactUsRequest />,
        exact: true,
        roles: ['admin']
    },
    {
        path: '/add-job',
        component: () => <EmployerAddJob />,
        element: <EmployerAddJob />,
        exact: true,
        roles: ['employer']
    },
    {
        path: '/edit-job/:jobId',
        component: () => <EmployerAddJob />,
        element: <EmployerAddJob />,
        exact: true,
        roles: ['employer']
    },
    {
        path: '/job-preview/:jobId',
        component: () => <AddJob />,
        element: <AddJob />,
        exact: true,
        roles: ['admin', 'employer']
    },
    {
        path: '/candidate-details/:id',
        component: () => <UserProfile />,
        element: <UserProfile />,
        exact: true,
        roles: ['employer', 'admin']
    },
    {
        path: '/recommended-candidate/:id/:jobId',
        component: () => <UserProfile />,
        element: <UserProfile />,
        exact: true,
        roles: ['employer', 'admin']
    },
    {
        path: '/job-list',
        component: () => <JobListing />,
        element: <JobListing listAccess='all' />,
        exact: true,
        roles: ['user', 'employer', 'admin']
    },
    {
        path: '/suggested-jobs',
        component: () => <SuggestedJobs />,
        element: <SuggestedJobs listAccess='all' />,
        exact: true,
        roles: ['user']
    },
    {
        path: '/edit-education/:detailId?',
        component: () => <EducationDetail />,
        element: <EducationDetail />,
        exact: true,
        roles: ['user']
    },
    {
        path: '/edit-experience/:detailId?',
        component: () => <ExperienceDetail />,
        element: <ExperienceDetail />,
        exact: true,
        roles: ['user']
    },
    {
        path: '/add-new-education/:detailId?',
        component: () => <EducationDetail />,
        element: <EducationDetail />,
        exact: true,
        roles: ['user']
    },
    {
        path: '/add-new-experience/:detailId?',
        component: () => <ExperienceDetail />,
        element: <ExperienceDetail />,
        exact: true,
        roles: ['user']
    },
    {
        path: '/profileSetup',
        component: () => <ProfileSetupStepOne />,
        element: <ProfileSetupStepOne />,
        exact: true,
        roles: ['user']
    },
    {
        path: '/profileSetup/addDetails/:key',
        component: () => <ProfileSetupStepTwo />,
        element: <ProfileSetupStepTwo />,
        exact: true,
        roles: ['user']
    },
    {
        path: '/profileSetup/EducationDetail/:detailId?',
        component: () => <EducationDetail />,
        element: <EducationDetail />,
        exact: true,
        roles: ['user']
    },
    {
        path: '/profileSetup/ExperienceDetail/:detailId?',
        component: () => <ExperienceDetail />,
        element: <ExperienceDetail />,
        exact: true,
        roles: ['user']
    },
    {
        path: '/job-details/:id',
        component: () => <JobDetails />,
        element: <JobDetails />,
        roles: ['user', 'employer', 'admin']
    },
    {
        path: '/profileSetup/LanguagesDetail',
        component: () => <LanguagesDetail />,
        element: <LanguagesDetail />,
        exact: true,
        roles: ['user']
    },
    {
        path: '/profileSetup/AvatarDetail',
        component: () => <AvatarDetail />,
        element: <AvatarDetail />,
        exact: true,
        roles: ['user']
    },
    {
        path: '/profileSetup/AdditionalInfos',
        component: () => <AdditionalInfos />,
        element: <AdditionalInfos />,
        exact: true,
        roles: ['user']
    },
    {
        path: '/applied-candidate/:jobId',
        component: () => <AppliedCandidate />,
        element: <AppliedCandidate />,
        exact: true,
        roles: ['employer', 'admin']
    },
    {
        path: '/404',
        component: () => <ErrorPages />,
        element: <ErrorPages errorCode={404} />,
        exact: true,
        roles: ['user', 'employer', 'admin']
    },
    {
        path: '*',
        component: () => <ErrorPages />,
        element: <ErrorPages errorCode={404} />,
        exact: true,
        roles: ['user', 'employer', 'admin']
    }
]
