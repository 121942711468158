import { Box, CircularProgress, Modal, Typography } from '@mui/material'
import React from 'react'
import variables from '../../settings/_variables.scss'
import DeleteIcon from '../../assets/icons/DELETE.svg'
import DELETECHECKICON from '../../assets/icons/DELETECHECK.svg'
import DELETECLOSEICON from '../../assets/icons/DELETECLOSE.svg'
import Button from '../Button'
import Icons from '../Icons'


const DeleteEntityBox = props => {
  const { open, entityId, deleteMutation, closeDelete, deleteLoading } = props
    return (
        <Modal
            open={open}
            backgroundColor='#fff'
            onClose={closeDelete}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: variables.WHITEOPACITY,
                position: 'fixed',
                top: '0',
                left: '0',
                backdropFilter: 'blur(2px)',
                zIndex: '1'
            }}
        >
            <Box
                  sx={{  
                    width: {
                        xs: '80%',
                        sm: '458px'
                    },
                    height: 'auto',
                    backgroundColor: variables.CYAN,
                    borderRadius: {xs: '50px', sm: '100px'},
                    margin: 'auto',
                    position: 'relative',
                    top: '25%',
                    textAlign: 'center',
                    p: 2
                }}
            >
                <Box
                    sx={{
                        height: '80px',
                        width: '80px',
                        margin: '0 auto',
                        backgroundColor: `${variables.ORANGE}`,
                        borderRadius: '50px'
                    }}
                >
                    <Box
                        component='img'
                        src={DeleteIcon}
                        sx={{
                            width: '37px',
                            height: '37px',
                            position: 'relative',
                            top: '20px'
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        marginTop: {
                            xs: 3,
                            sm: 6
                        }
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '20px',
                            color: variables.BLUE,
                            fontWeight: '600',
                            fontFamily: variables.poppinsRegular
                        }}
                    >
                        Do you really want to delete this?
                    </Typography>
                    {deleteLoading ? (
                        <CircularProgress sx={{ color: variables.BLUE }} />
                    ) : (
                        <Box
                            sx={{
                                marginTop: {
                                    xs: 3,
                                    sm: 6
                                }
                            }}
                        >
                            <Box>
                                <Button
                                    label='Yes'
                                    color='secondary'
                                    variant='contained'
                                    endIcon={
                                        <Icons sx={{ width: '20px',height: '20px' }} src={DELETECHECKICON} />
                                    }
                                    size='large'
                                    sx={{
                                        padding: '8px 40px',
                                        fontWeight: '600',
                                        backgroundColor: '#FFFAF4',
                                        m: 1,
                                        '&:hover': {
                                            backgroundColor: '#FFFAF4'
                                        }
                                    }}
                                    onClick={() => {
                                        deleteMutation({
                                            variables: {
                                                id: entityId,
                                                actionType: 'delete',
                                                status: props.entityStatus
                                            }
                                        })
                                    }}
                                />
                                <Button
                                    label='No'
                                    color='secondary'
                                    variant='contained'
                                    endIcon={
                                        <Icons sx={{ height: '20px', width:"20px" }} src={DELETECLOSEICON} />
                                    }
                                    size='large'
                                    sx={{
                                        m: 1,
                                        fontWeight: '600',
                                        padding: '8px 40px',
                                        backgroundColor: '#FFFAF4',
                                        '&:hover': {
                                            backgroundColor: '#FFFAF4'
                                        }
                                    }}
                                    onClick={() => closeDelete()}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    )
}

export default DeleteEntityBox
