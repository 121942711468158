import { Box, Chip, styled } from '@mui/material'
import React from 'react'
import CheckIcon from '../../assets/icons/CHECK.svg'
import variables from '../../settings/_variables.scss'

const StyledChip = styled(Chip)`
    padding: 16px;
    margin: 16px 16px 0 0;
    background-color: ${variables.CYAN1};
    border-color: ${variables.CYAN1};
`

const StyledCheckedBox = styled(Box)`
    height: 25px;
    width: 25px;
    border-radius: 35px;
    text-align: center;
    border: 1px solid ${variables.CYAN};
    background-color: ${variables.CYAN};
`

const StyledCheckedBoxUnchecked = styled(Box)`
    height: 25px;
    width: 25px;
    border-radius: 35px;
    text-align: center;
    border: 1px solid ${variables.CYAN};
    background-color: ${variables.WHITE1};
`

const RegistrationChipBox = props => {
    return (
        <StyledChip
            disabled={props.disabled}
            label={props.label}
            icon={
                props.checked ? (
                    <StyledCheckedBox>
                        <Box
                            component='img'
                            src={CheckIcon}
                            sx={{ position: 'relative', top: '25%', height: '12px' }}
                        />
                    </StyledCheckedBox>
                ) : (
                    // <Icons
                    //     src={CheckboxIcon}
                    //     style={{
                    //         marginRight: '0px'
                    //     }}
                    // />
                    <StyledCheckedBoxUnchecked />
                )
            }
            variant='outlined'
            {...props}
        />
    )
}

export default RegistrationChipBox
