import { gql, useQuery } from '@apollo/client'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import variables from '../../settings/_variables.scss'
import Loader from '../Loader'
import Button from '../Button'
import DownloadIcon from '@mui/icons-material/Download'
import EmptyListSection from '../elements/EmptyListSection'
import ContactUsListRow from '../molecules/ContactUsListRow'
import LoadMoreLink from '../elements/LoadMoreLink'

const GET_CONTACT_US_DATA_QUERY = gql`
    query GetContactUsData($afterCursor: String) {
        getContactUsData(first: 8, after: $afterCursor) {
            edges {
                node {
                    id
                    name
                    email
                    createdAt
                    message
                    user {
                        id
                    }
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
                hasPreviousPage
            }
        }
        getContactUsDataCount
    }
`

const ContactUsList = props => {
    // const [downloadExcel, setDownloadExcel] = useState(false)
    const [listLoader, setListLoader] = useState(false)
    const { loading, error, data, fetchMore, refetch } = useQuery(GET_CONTACT_US_DATA_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            afterCursor: ''
        },
        onCompleted(data) {
			setListLoader(false)
		},
        onError(error) {}
    })
    if (loading) return <Loader />
    const contactUsData = data.getContactUsData.edges.map(edge => edge.node)
    const pageInfo = data.getContactUsData.pageInfo
    return (
        <Box>
            <Grid container spacing={{ xs: 1, sm: 3 }} alignItems='center'>
                <Grid item md={4} lg={4} xl={5} xs={6} sm={4}>
                    <Typography variant='h2' className='strong'>
                        Contact Us Requests
                        {data.getContactUsDataCount > 0 ? ` (${data.getContactUsDataCount})` : ''}
                    </Typography>
                </Grid>
                {/* <Grid
                    item
                    md={8}
                    lg={8}
                    sm={8}
                    xs={6}
                    sx={{ textAlign: 'right', display: 'flex', justifyContent: 'end' }}
                >
                    <Button
                        label={
                            downloadExcel ? (
                                <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                    <CircularProgress
                                        size={20}
                                        sx={{
                                            color: variables.BLUE,
                                            mr: {
                                                xs: 0,
                                                md: 2
                                            }
                                        }}
                                    />{' '}
                                    <Typography
                                        sx={{
                                            display: {
                                                xs: 'none',
                                                md: 'block'
                                            },
                                            fontSize: '16px',
                                            fontWeight: '900'
                                        }}
                                    >
                                        Exporting to CSV
                                    </Typography>
                                </Box>
                            ) : (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <DownloadIcon
                                        sx={{
                                            color: variables.WHITE,
                                            fontSize: {
                                                xs: '25px',
                                                md: '20px'
                                            },
                                            mr: {
                                                xs: 0,
                                                md: 1
                                            }
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            display: {
                                                xs: 'none',
                                                md: 'block'
                                            },
                                            fontSize: '16px',
                                            fontWeight: '900'
                                        }}
                                    >
                                        Export to CSV
                                    </Typography>
                                </Box>
                            )
                        }
                        color='primary'
                        variant='contained'
                        disabled={downloadExcel}
                        size='large'
                        sx={{ p: { xs: 1, sm: 2 } }}
                        onClick={e => {
                            e.preventDefault()
                            // setDownloadExcel(true)
                            // exportCsvMutation({
                            //     variables: {
                            //         requestType: 'user'
                            //     }
                            // })
                        }}
                    />
                </Grid> */}
            </Grid>

            <Box
                sx={{
                    my: 6
                }}
            >
                {contactUsData && contactUsData.length > 0 ? (
                    contactUsData.map((request, index) => (
                        <ContactUsListRow data={request} key={index} />
                    ))
                ) : (
                    <EmptyListSection message='no contact request at the moment.' />
                )}
            </Box>
            {pageInfo && pageInfo.hasNextPage ? (
				listLoader ? (
					<Box
						sx={{
							textAlign: 'center'
						}}
					>
						<CircularProgress
							size={20}
							sx={{
								height: '20px',
								width: '20px',
								color: variables.BLUE
							}}
						/>
					</Box>
				) : (
					<LoadMoreLink
						onClick={() => {
							if (pageInfo.hasNextPage) {
								setListLoader(true)
								fetchMore({
									variables: {
										afterCursor: pageInfo.endCursor ? pageInfo.endCursor : ''
									}
								})
							}
						}}
					/>
				)
			) : null}
        </Box>
    )
}

export default ContactUsList
