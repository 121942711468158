import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { StyledIcons } from '../../base/commonStyles'
import {
	getExperienceLabel,
	getStringFromArray,
	truncateDetails
} from '../../utils/commonFunctions'
import Experience from '../../assets/icons/EXPERIENCE.svg'
import Industry from '../../assets/icons/INDUSTRY.svg'
import variables from '../../settings/_variables.scss'
import { gql, useQuery } from '@apollo/client'
import { Link, useLocation, useParams } from 'react-router-dom'
import { getAvatrURL } from '../../utils/utilities'
import AvatarDefault from '../../assets/images/avatar.png'
import LoadMoreLink from '../elements/LoadMoreLink'

const GET_RECOMMENDATION_QUERY = gql`
	query GetRecommendedCandidates($id: ID!, $afterCursor: String) {
		getRecommendedCandidates(first: 3, id: $id, after: $afterCursor) {
			edges {
				node {
					id
					title
					experience
					candidateAvatar
					userId
					otherIndustry
					industries {
						id
						name
					}
				}
			}
			pageInfo {
				startCursor
				endCursor
				hasNextPage
				hasPreviousPage
			}
		}
	}
`

const BuntonRecommendations = props => {
	const params = useParams()
	const [listLoader, setListLoader] = useState(false)

	const location = useLocation()
	const { loading, data, fetchMore } = useQuery(GET_RECOMMENDATION_QUERY, {
		fetchPolicy: 'network-only',
		variables: {
			afterCursor: '',
			id: params.id ? params.id : params.jobId ? params.jobId : 0
		},
		onCompleted(data) {
			setListLoader(false)
		},
		onError(error) {}
	})
	if (loading)
		return (
			<Box
				sx={{
					textAlign: 'center'
				}}
			>
				<CircularProgress
					sx={{
						color: variables.BLUE
					}}
				/>
			</Box>
		)

	const candidateData = data.getRecommendedCandidates.edges.map(edge => edge.node)
	const pageInfo = data.getRecommendedCandidates.pageInfo
	return (
		<Box sx={{ maxWidth: '360px', m: 'auto' }}>
			<Box sx={{ borderBottom: `2px solid ${variables.CYAN}`, pb: 2 }}>
				<Typography variant='h3' className='strong'>
					Recommendations
				</Typography>
			</Box>

			{candidateData && candidateData.length > 0
				? candidateData.map(candidate => (
					<Box
						sx={{
							my: 3
						}}
					>
						<Grid container alignItems='center' columnSpacing={2}>
							<Grid item xl={3} lg={3.5} md={4} sm={3} xs={4}>
								<Box
									sx={{
										height: {
											xs: '70px',
											md: '50px'
										},
										width: {

											xs: '70px',
											md: '50px'
										},
										border: `3px solid ${variables.CYAN}`,
										borderRadius: '50px'
									}}
									component='img'
									src={
										candidate.candidateAvatar
											? getAvatrURL(candidate.candidateAvatar)
											: AvatarDefault
									}
								/>
							</Grid>
							<Grid
								item
								xl={9} lg={8.5}
								md={8}
								sm={9}
								xs={8}
							>
								{candidate.industries.length > 0 ? (
									<Box>
										<Typography variant='h5' className='small-size'>
											<Box
												sx={{
													alignItems:'center',
													display: 'flex'
												}}
											>
												<StyledIcons component='img' src={Industry} sx={{mr: 1, height: '12px', width: '12px'}}/>
												<Typography>
													{truncateDetails(
														getStringFromArray(
															candidate.industries,
															candidate.otherIndustry
														),
														15
													)}
												</Typography>
											</Box>
										</Typography>
									</Box>
								) : null}
								<Box>
									<Typography
											variant='h5'
											className='small-size-strong'
											sx={{
												mt: '4px'
											}}
									>
										<Link
											to={`/recommended-candidate/${candidate.userId}/${
												location.pathname.includes('job-details')
													? params.id
													: params.jobId
											}`}
										>
											{candidate.title ? candidate.title : 'Anonymous User'}
										</Link>
									</Typography>
								</Box>
								{candidate.experience ? (
									<Box
										sx={{
											alignItems:'center',
											display: 'flex',
											mt: '4px'
										}}
									>
										<StyledIcons component='img' src={Experience} sx={{mr: 1, height: '12px', width: '12px'}}/>
										<Typography variant='h5' className='small-size'>
											{truncateDetails(
												getExperienceLabel(candidate.experience),
												15
											)}
										</Typography>
									</Box>
								) : null}
							</Grid>
						</Grid>
					</Box>
				))
				: 'No Recommendations found.'}
			{pageInfo && pageInfo.hasNextPage ? (
				listLoader ? (
					<Box
						sx={{
							textAlign: 'center'
						}}
					>
						<CircularProgress
							size={20}
							sx={{
								height: '20px',
								width: '20px',
								color: variables.BLUE
							}}
						/>
					</Box>
				) : (
					<LoadMoreLink
						onClick={() => {
							if (pageInfo.hasNextPage) {
								setListLoader(true)
								fetchMore({
									variables: {
										afterCursor: pageInfo.endCursor ? pageInfo.endCursor : ''
									}
								})
							}
						}}
					/>
				)
			) : null}
		</Box>
	)
}

export default BuntonRecommendations
