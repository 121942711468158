import React from 'react'
import Layout from '../template/Layout'
import {
    addHttpsToUrl,
    getCompanyIndustries,
    getCompanyStates,
    getEmployeesLabel,
    GetLoginDetails,
    otherBenefitsChips
} from '../utils/commonFunctions'
import { useQuery } from '@apollo/client'
import { GET_COMPANY_DATA_MUTATION } from '../queries/addCompanyQueries'
import Loader from '../components/Loader'
import { useParams } from 'react-router-dom'
import { Box, Container, Divider, Grid, Typography } from '@mui/material'
import DefaultCompanyLogo from '../components/molecules/DefaultCompanyLogo'
import variables from '../settings/_variables.scss'
import {
    StyledBenefitsChips
} from '../base/commonStyles'
import IconTextBox from '../components/elements/IconTextBox'
import ReactHtmlParser from 'react-html-parser'

import WebsiteIcon from '../assets/icons/website.svg'
import LocationIcon from '../assets/icons/LOCATION.svg'
import StrengthIcon from '../assets/icons/profile.svg'
import LinkedInIcon from '../assets/icons/linkedin.svg'
import { useSelector } from 'react-redux'
import CompanyProfile from '../views/UserProfile/CompanyProfile'

const CompanyProfilePage = props => {
    const { isUserAuthenticated } = GetLoginDetails()
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const params = useParams()

    const {
        loading,
        data,
        error,
        refetch: refetchUserDetail
    } = useQuery(GET_COMPANY_DATA_MUTATION, {
        fetchPolicy: 'network-only',
        variables: {
            id: params.id
        }
    })
    if (loading) return <Loader />

    const companyDetails = data.company

    return (
        <Layout>
            <Container
                maxWidth='xl'
                disableGutters
                sx={{
                    p: {
                        xs: '0px 10px',
                        sm: '0px 20px',
                        md: '0px 30px',
                        lg: '0px 50px',
                        xl: '0px 80px'
                    },
                    mt: { xs: 3, sm: 7, md: 10 }
                }}
            >
                <Grid container spacing={5}>
                    <Grid item md={5} sm={12} xs={12} lg={4} xl={4}>
                        <Grid
                            container
                            spacing={3}
                            alignItems={{ xs: 'center', sm: 'start' }}
                            sx={{
                                mb: 6
                            }}
                        >
                            <Grid
                                item
                                xl={4}
                                lg={3}
                                md={4}
                                sm={3}
                                xs={12}
                                sx={{
                                    textAlign: {
                                        xs: 'center',
                                        sm: ' left'
                                    }
                                }}
                            >
                                <DefaultCompanyLogo
                                    imgSrc={
                                        companyDetails.companyLogoUrl
                                            ? companyDetails.companyLogoUrl
                                            : null
                                    }
                                />
                            </Grid>
                            <Grid item xl={6} lg={5} md={6} sm={7} xs={9}>
                                <Box
                                    sx={{
                                        color: variables.BLUE
                                    }}
                                >
                                    <Box>
                                        <Typography
                                            variant='h2'
                                            className='font-size-28px extrabold'
                                            sx={{
                                                wordBreak: 'break-word'
                                            }}
                                        >
                                            {companyDetails.companyName}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant='h3' className='small-size-18px'>
                                            {getCompanyIndustries(companyDetails.industries)}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                        {companyDetails.companyWebsite ? (
                            <Box
                                sx={{
                                    wordWrap: 'break-word',
                                    width: 'fit-content',
                                    wordBreak: 'break-all'
                                }}
                            >
                                <Box
                                    component='a'
                                    href={addHttpsToUrl(companyDetails.companyWebsite)}
                                    target='_blank'
                                    sx={{}}
                                >
                                    <IconTextBox
                                        iconSrc={WebsiteIcon}
                                        text={companyDetails.companyWebsite}
                                    />
                                </Box>
                            </Box>
                        ) : null}

                        <Box
                            sx={{
                                my: 2
                            }}
                        >
                            <Box
                                sx={{
                                    mb: 1
                                }}
                            >
                                {companyDetails.states ? (
                                    <IconTextBox
                                        iconSrc={LocationIcon}
                                        text={getCompanyStates(companyDetails.states)}
                                    />
                                ) : null}
                            </Box>

                            {companyDetails.noOfEmployees ? (
                                <Box
                                    sx={{
                                        mb: 1
                                    }}
                                >
                                    <IconTextBox
                                        iconSrc={StrengthIcon}
                                        text={getEmployeesLabel(companyDetails.noOfEmployees)}
                                    />
                                </Box>
                            ) : null}

                            {companyDetails.linkedinUrl ? (
                                <Box
                                    component='a'
                                    href={companyDetails.linkedinUrl}
                                    target='_blank'
                                    sx={{
                                        wordWrap: 'break-word',
                                        width: 'fit-content',
                                        wordBreak: 'break-all'
                                    }}
                                >
                                    <IconTextBox
                                        iconSrc={LinkedInIcon}
                                        text='Visit LinkedIn Page'
                                    />
                                </Box>
                            ) : null}
                        </Box>
                        <Divider
                            sx={{
                                my: 4,
                                borderColor: variables.CYAN
                            }}
                        />
                        <Box>
                            <Box
                                sx={{
                                    marginBottom: '10px'
                                }}
                            >
                                <Typography
                                    variant='h3'
                                    className='small-size-18px strong extrabold'
                                >
                                    About
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        color: variables.BLUE
                                    }}
                                >
                                    {ReactHtmlParser(companyDetails.description)}
                                </Typography>
                            </Box>
                        </Box>

                        <Box container>
                            {['mob', 'tab'].includes(responsiveBreakpoint) && (
                                <Divider
                                    sx={{
                                        marginTop: '30px',
                                        marginBottom: '30px',
                                        borderColor: variables.CYAN
                                    }}
                                />
                            )}

                            <Box sx={{ mt: '30px' }}>
                                <Typography
                                    variant='h3'
                                    className='small-size-18px strong extrabold'
                                >
                                    Employee Benefits
                                </Typography>
                            </Box>
                            <Box>
                                {companyDetails.benefits && companyDetails.benefits.length > 0
                                    ? companyDetails.benefits.map(benefit =>
                                          benefit.name !== 'Others' ? (
                                              <StyledBenefitsChips
                                                  label={benefit.name}
                                                  variant='outlined'
                                                  sx={{
                                                      fontSize: '15px',
                                                      fontWeight: '400'
                                                  }}
                                              />
                                          ) : benefit.name === 'Others' &&
                                            companyDetails.otherBenefits &&
                                            companyDetails.otherBenefits !== '' ? (
                                              otherBenefitsChips(companyDetails.otherBenefits)
                                          ) : (
                                              'N/A'
                                          )
                                      )
                                    : 'N/A'}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={7}
                        sm={12}
                        xs={12}
                        lg={8}
                        xl={8}
                        sx={{
                            my: {
                                xs: '20px',
                                md: '0px'
                            }
                        }}
                    >
                        <CompanyProfile
                            isEdit={false}
                            company={companyDetails}
                            isUserAuthenticated={isUserAuthenticated}
                            refetch={refetchUserDetail}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}

export default CompanyProfilePage
