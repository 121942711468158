import { Box, Typography } from '@mui/material'
import React from 'react'

const ModalStatusSection = props => {
    const { statusIcon, statusText, statusType } = props

    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
            }}
        >
            <Box
                sx={{
                    textAlign: 'center',
                    p: 3
                }}
            >
                {statusIcon && (
                    <Box
                        sx={{
                            mb: 2
                        }}
                    >
                        <Box
                            component='img'
                            src={props.statusIcon}
                            sx={{
                                width: '60px',
                                height: '60px'
                            }}
                        />
                    </Box>
                )}
                <Box>
                    <Typography variant='h2' className='font-size-28px extrabold'>
                        {statusText}
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default ModalStatusSection
