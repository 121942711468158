import { Box, Typography, Link as MuiLink } from '@mui/material'
import React from 'react'
import { Button } from '../../components'
import { TextStrings } from '../../constants'
import { styles } from './styles'
import { Link as RouterLink} from 'react-router-dom'
import BuntonLogo from '../../assets/icons/LOGO_WITH_TEXT.svg'
import { LoginSocialLinkedin } from 'reactjs-social-login'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'

const REDIRECT_URI = `${process.env.REACT_APP_SOCIAL_LOGIN_REDIRECT_URI}/registration`


const StepOne = (props) => {
    return <Box
    sx={[
        styles.styleOneContainer,
        {
            alignItems: 'center'
        }
    ]}
>
    <Box sx={styles.welcomeLogoBox}>
        <RouterLink to='/'>
            <Box component='img' src={BuntonLogo} sx={styles.welcomeLogo} />
        </RouterLink>
    </Box>

    <Typography variant='h5' gutterBottom sx={styles.welcomeToButon}>
        {TextStrings.welcome}
    </Typography>

    <Typography sx={styles.welcomeToButonText}>{TextStrings.welcomeText}</Typography>
    <Button
        label={TextStrings.start}
        color='primary'
        variant='contained'
        size='large'
        onClick={() => {
            props.setShowStepOne(false)
        }}
        sx={styles.startButton}
        endIcon={<ArrowRightAltIcon />}
    />
    <Box sx={styles.alreadyHaveAccText}>
        {TextStrings.alreadyHaveAccount}
        <MuiLink variant='body2' sx={styles.link} href='/login'>
            <strong>{TextStrings.login}</strong>
        </MuiLink>
    </Box>
    <LoginSocialLinkedin
        isOnlyGetToken
        client_id={process.env.REACT_APP_LINKEDIN_LOGIN_CLIENT_ID}
        client_secret={process.env.REACT_APP_LINKEDIN_LOGIN_CLIENT_SECRET}
        redirect_uri={REDIRECT_URI}
        scope={'r_liteprofile%20r_emailaddress'}
        onLoginStart={props.onLoginStart}
        onResolve={props.handleLinkedinLogin}
        onReject={err => {
            props.setApiError(err)
        }}
    ></LoginSocialLinkedin>
</Box>
}

export default StepOne