import React, { useState } from 'react'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { useMutation } from '@apollo/client'
import { LIKE_JOB_MUTATION } from '../../queries/jobPostQueries'
import Button from '../Button'
import variables from '../../settings/_variables.scss'
import { Box, Typography } from '@mui/material'
import { abrevatedLikesCount } from '../../utils/commonFunctions'

const LikeButton = props => {
    const { userId, jobPostId, isLiked, totalLikes, role, isUserAuthenticated } = props
    const [isJobLiked, setIsJobLiked] = useState(isLiked)
    const [likes, setLikes] = useState(
        Number(jobPostId.toString().split('').pop()) + 1 + totalLikes
    )
    const [likeJobMutation] = useMutation(LIKE_JOB_MUTATION, {
        onCompleted(data) {
            setIsJobLiked(true)
            setLikes(likes + 1)
            if (props.refetchAppliedJobCount) {
                props.refetchAppliedJobCount()
            }
        }
    })
    return (
        <Box>
            <Button
                color='primary'
                variant='contained'
                size='large'
                sx={{
                    width: 'auto',
                    height: {
                        xs: '42px',
                        md: '47px'
                    },
                    minWidth: '80px',
                    p: '11px',
                    background: variables.BLUE,
                    color: variables.WHITE,
                    '&:disabled': {
                        background: variables.BLUE,
                        color: variables.WHITE
                    },
                    ...props.sx
                }}
                label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {!isUserAuthenticated ||
                        (isUserAuthenticated &&
                            (isJobLiked || ['admin', 'employer'].includes(role))) ? (
                            <FavoriteIcon sx={{ fontSize: '18px' }} />
                        ) : (
                            <FavoriteBorderIcon />
                        )}
                        <Typography
                            variant='h5'
                            className='small-size-strong font-color-white'
                            sx={{ ml: 1, color: variables.WHITE }}
                        >
                            {abrevatedLikesCount(likes)}
                        </Typography>
                    </Box>
                }
                disabled={
                    !isUserAuthenticated ||
                    (isUserAuthenticated && (isJobLiked || ['admin', 'employer'].includes(role)))
                }
                onClick={e => {
                    e.preventDefault()
                    if (isUserAuthenticated) {
                        likeJobMutation({
                            variables: {
                                userId: userId,
                                jobId: jobPostId
                            }
                        })
                    }
                }}
            />
        </Box>
    )
}

export default LikeButton
