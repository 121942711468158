import { useQuery } from '@apollo/client'
import { Box, Container, Divider, Grid, styled, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import {
    StyledBenefitsChips,
    StyledExtraDetails,
    StyledIconBox,
    StyledIcons,
    StyledTextIconBox
} from '../../../base/commonStyles'
import Icons from '../../../components/Icons'
import Loader from '../../../components/Loader'
import { ANONYMOUS_USER_DETAILS, USER_DETAILS } from '../../../queries/userDetailsQuery'
import {
    getCompanyIndustries,
    getEmployeesLabel,
    getExperienceLabel,
    getCompanyDepartments,
    getStringFromArray,
    getCompanyStates,
    addHttpsToUrl,
    otherBenefitsChips
} from '../../../utils/commonFunctions'
import { getAvatrURL } from '../../../utils/utilities'
import CandidateProfile from '../CandidateProfile'
import CompanyProfile from '../CompanyProfile'
import variables from '../../../settings/_variables.scss'
import Location from '../../../assets/icons/LOCATION.svg'
import Department from '../../../assets/icons/DEPARTMENT.svg'
import LinkedInIcon from '../../../assets/icons/linkedin.svg'

import EmailIcon from '../../../assets/icons/MAIL.svg'

import Experience from '../../../assets/icons/EXPERIENCE.svg'

import StrengthIcon from '../../../assets/icons/profile.svg'
import LanguageIcon from '../../../assets/icons/LANGUAGE.svg'
import WebsiteIcon from '../../../assets/icons/website.svg'
import EDITICON from '../../../assets/icons/EDIT.svg'
import IndustryIcon from '../../../assets/icons/INDUSTRY.svg'
import DownloadIcon from '../../../assets/icons/download.svg'
import StatusBox from '../../../components/StatusBox'
import Tooltip from '@mui/material/Tooltip'
import DefaultProfilIcon from '../../../assets/images/avatar.png'
import ReactHtmlParser from 'react-html-parser'
import DefaultCompanyLogo from '../../../components/molecules/DefaultCompanyLogo'
import { Button } from '../../../components'


const StyledBox = styled(Box)`
    width: 83px;
    height: 83px;
    border: 3px solid ${variables.CYAN};
    border-radius: 50px;
`

const Profile = props => {
    const { loginCheckAuthDetails, loginDetails } = useSelector(
        state => state.login
    )
    const { responsiveBreakpoint } = useSelector(state => state.initial)

    const {
        isUserInvitedToApply,
        isUserApplied,
        isAnonyomusProfile,
    } = props
    const [isSuccess, setIsSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()

    const isMyProfilePage = location.pathname === '/my-profile'

    const userId = isMyProfilePage
        ? loginCheckAuthDetails.id
            ? loginCheckAuthDetails.id
            : loginDetails.id
            ? loginDetails.id
            : 0
        : params.id

    const currentUserRole = loginCheckAuthDetails && loginCheckAuthDetails.userRole
        ? loginCheckAuthDetails.userRole
        : loginDetails && loginDetails.role
        ? loginDetails.role
        : null

    const {
        loading,
        data,
        error,
        refetch: refetchUserDetail
    } = useQuery(isAnonyomusProfile ? ANONYMOUS_USER_DETAILS : USER_DETAILS, {
        fetchPolicy: 'network-only',
        variables: {
            id: userId
        }
    })
    if (loading) return <Loader />

    const userDetails = data.user
    const { name, email, userRole, company, profileImage, candidate, linkedinUrl } = userDetails
    const isCandidateProfile = userRole === 'user'
    if (
        (userRole === 'user' && location.pathname.includes('company-details')) ||
        (userRole === 'employer' && location.pathname.includes('candidate-details'))
    ) {
        navigate('/dashboard', { replace: true })
    }
    const organizedData = {
        userId: userDetails.id,
        name:
            isCandidateProfile && !isAnonyomusProfile ? name : company ? company.companyName : null,
        email: isCandidateProfile && !isAnonyomusProfile ? email : null,
        jobTitle: isCandidateProfile && candidate && candidate.title ? candidate.title : null,
        companyIndustry: null,
        linkedinUrl: linkedinUrl && ((isCandidateProfile && !isAnonyomusProfile) || company) ? addHttpsToUrl(linkedinUrl) : null,
        location:
            isCandidateProfile && candidate && candidate.states
                ? getStringFromArray(candidate.states)
                : company && company.states
                ? getCompanyStates(company.states)
                : null,
        industries:
            isCandidateProfile && candidate && candidate.industries
                ? getStringFromArray(candidate.industries, candidate.otherIndustry)
                : company && company.industries
                ? getCompanyIndustries(company.industries)
                : null,

        departments:
            isCandidateProfile && candidate && candidate.departments
                ? getStringFromArray(candidate.departments, candidate.otherDepartment)
                : company && company.departments
                ? getCompanyDepartments(company.departments)
                : null,

        benefits:
            isCandidateProfile && candidate && candidate.benefits
                ? candidate.benefits
                : company && company.benefits
                ? company.benefits
                : null,
        experience:
            isCandidateProfile && candidate && candidate.experience
                ? getExperienceLabel(candidate.experience)
                : null,
        employeeStrength:
            !isCandidateProfile && company && company.noOfEmployees
                ? getEmployeesLabel(company.noOfEmployees)
                : null,
        languages:
            isCandidateProfile && candidate && candidate.language ? candidate.language : null,
        website:
            !isCandidateProfile && company && company.companyWebsite
                ? company.companyWebsite
                : null,
        about:
            isCandidateProfile && !isAnonyomusProfile && candidate && candidate.about
                ? candidate.about
                : company && company.description
                ? company.description
                : null,
        companyLogo: !isCandidateProfile && company && company.companyLogoUrl,
        userAvatar: isCandidateProfile ? profileImage : '',
        candidateResume: !isAnonyomusProfile && candidate && candidate.candidateResumeLink ? candidate.candidateResumeLink : '',
        otherBenefits: isCandidateProfile && candidate && candidate.otherBenefits ? candidate.otherBenefits : company && company.otherBenefits
        ? company.otherBenefits
        :''
    }
    return (
        <Container
            maxWidth='xl'
            disableGutters
            sx={{
                p: {
                    xs: '0px 20px',
                    sm: '0px 40px',
                    md: '0px 60px',
                    lg: '0px 80px'
                },
                mt: { xs: 0, sm: 4, md: 6 }
            }}
        >
            <Grid container spacing={{xs:3, sm: 5}}>
                <Grid item md={5} sm={12} xs={12} lg={4} xl={4}>
                    <Grid
                        container
                        spacing={3}
                        alignItems={{ xs: 'center', sm: 'start' }}
                        sx={{
                            mb: 3
                        }}
                    >
                        <Grid
                            item
                            xl={4}
                            lg={3.5}
                            md={4}
                            sm={3}
                            xs={12}
                            sx={{
                                textAlign: {
                                    xs: 'center',
                                    sm: ' left'
                                }
                            }}
                        >
                            {isCandidateProfile ? (
                                organizedData.userAvatar ? (
                                    <StyledBox
                                        component='img'
                                        src={getAvatrURL(organizedData.userAvatar)}
                                    />
                                ) : (
                                    <StyledBox component='img' src={DefaultProfilIcon} />
                                )
                            ) : (
                                <DefaultCompanyLogo
                                    imgSrc={
                                        organizedData.companyLogo ? organizedData.companyLogo : null
                                    }
                                />
                            )}
                        </Grid>
                        <Grid item xl={6} lg={5} md={6} sm={7} xs={9}>
                            <Box
                                sx={{
                                    color: variables.BLUE
                                }}
                            >
                                <Box>
                                    <Typography
                                        variant='h2'
                                        className='font-size-28px extrabold'
                                        sx={{
                                            wordBreak: 'break-word'
                                        }}
                                    >
                                        {isAnonyomusProfile
                                            ? organizedData.jobTitle
                                            : organizedData.name}
                                    </Typography>
                                </Box>
                                {!isAnonyomusProfile ? (
                                    <Box>
                                        <Typography variant='h3' className='small-size-18px'>
                                            {isCandidateProfile
                                                ? organizedData.jobTitle
                                                : organizedData.industries}
                                        </Typography>
                                    </Box>
                                ) : null}
                            </Box>
                        </Grid>
                        {isMyProfilePage ? (
                            <Grid
                                item
                                md={2}
                                sm={2}
                                xs={3}
                                lg={3.5}
                                xl={2}
                                sx={{ display: 'flex', justifyContent: 'end' }}
                            >
                                <Tooltip title='Edit'>
                                    <Link
                                        to={
                                            isCandidateProfile
                                                ? '/edit-profile/'
                                                : '/edit-company/' + company?.id
                                        }
                                    >
                                        <Icons
                                            src={EDITICON}
                                            sx={{
                                                height: '30px',
                                                width: '30px'
                                            }}
                                        />
                                    </Link>
                                </Tooltip>
                            </Grid>
                        ) : null}
                    </Grid>
                    {!isAnonyomusProfile ? (
                        <Box sx={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                            {isCandidateProfile ? (organizedData.email ? (
                                <StyledTextIconBox>
                                    <StyledIcons src={EmailIcon} />
                                    <StyledExtraDetails>{organizedData.email}</StyledExtraDetails>
                                </StyledTextIconBox>
                            ) : null) : organizedData.website ? (
                                <StyledTextIconBox>
                                    <StyledIcons src={WebsiteIcon} />
                                    <StyledExtraDetails>{organizedData.website}</StyledExtraDetails>
                                </StyledTextIconBox>
                            ): null}
                        </Box>
                    ) : null}

                    <Box
                        sx={{
                            my: 2
                        }}
                    >
                        <Box
                            sx={{
                                mb: 1
                            }}
                        >
                            {isCandidateProfile ? (
                                organizedData.industries ? (
                                    <StyledTextIconBox>
                                        <StyledIcons src={IndustryIcon} />
                                        <StyledExtraDetails>
                                            {organizedData.industries}
                                        </StyledExtraDetails>
                                    </StyledTextIconBox>
                                ) : null
                            ) : organizedData.location ? (
                                <StyledTextIconBox>
                                    <StyledIcons src={Location} />
                                    <StyledExtraDetails>
                                        {organizedData.location}
                                    </StyledExtraDetails>
                                </StyledTextIconBox>
                            ) : null}
                        </Box>

                        <Box
                            sx={{
                                mb: 1
                            }}
                        >
                            {isCandidateProfile ? (
                                organizedData.location ? (
                                    <StyledTextIconBox>
                                        <StyledIcons src={Location} />
                                        <StyledExtraDetails>
                                            {organizedData.location}
                                        </StyledExtraDetails>
                                    </StyledTextIconBox>
                                ) : null
                            ) : null}
                        </Box>
                        <Box
                            sx={{
                                mb: 1
                            }}
                        >
                            {isCandidateProfile ? (
                                organizedData.departments ? (
                                    <StyledTextIconBox>
                                        <StyledIcons src={Department} />
                                        <StyledExtraDetails>
                                            {organizedData.departments}
                                        </StyledExtraDetails>
                                    </StyledTextIconBox>
                                ) : null
                            ) : null}
                        </Box>
                        <Box
                            sx={{
                                mb: 1
                            }}
                        >
                            {isCandidateProfile && organizedData.experience  ? (
                                <StyledTextIconBox>
                                    <StyledIcons src={Experience} />
                                    <StyledExtraDetails>
                                        {organizedData.experience}
                                    </StyledExtraDetails>
                                </StyledTextIconBox>
                            ) : organizedData.employeeStrength ? (
                                <StyledTextIconBox>
                                    <StyledIcons src={StrengthIcon} />
                                    <StyledExtraDetails>
                                        {organizedData.employeeStrength}
                                    </StyledExtraDetails>
                                </StyledTextIconBox>
                            ) : null}
                        </Box>

                        {isCandidateProfile && organizedData.languages ? (
                            <Box
                                sx={{
                                    mb: 1
                                }}
                            >
                                <StyledTextIconBox>
                                    <StyledIcons src={LanguageIcon} />
                                    <StyledExtraDetails sx={{ wordBreak: 'break-all' }}>
                                        {organizedData.languages}
                                    </StyledExtraDetails>
                                </StyledTextIconBox>
                            </Box>
                        ) : null}
                        {organizedData.linkedinUrl ? (
                            <Box>
                                <StyledTextIconBox>
                                    <StyledIcons src={LinkedInIcon} />
                                    <Box
                                        component='a'
                                        href={organizedData.linkedinUrl}
                                        target='_blank'
                                    >
                                        <StyledExtraDetails sx={{ wordBreak: 'break-all' }}>
                                            {isCandidateProfile
                                                ? 'View LinkedIn Profile'
                                                : 'Visit LinkedIn Page'}
                                        </StyledExtraDetails>
                                    </Box>
                                </StyledTextIconBox>
                            </Box>
                        ) : null}
                    </Box>
                    <Divider
                        sx={{
                            my: 4,
                            borderColor: variables.CYAN
                        }}
                    />
                    {!isAnonyomusProfile ? (
                        <Box>
                            <Box
                                sx={{
                                    marginBottom: '10px'
                                }}
                            >
                                <Typography variant='h3' className='small-size-18px strong extrabold'>About</Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        color: variables.BLUE
                                    }}
                                >
                                    {ReactHtmlParser(organizedData.about)}
                                </Typography>
                            </Box>
                        </Box>
                    ) : null}

                    {!isAnonyomusProfile && organizedData.candidateResume && (
                        <Box sx={{ minHeight: '50px', position: 'relative', my: 4 }}>
                            <Button
                                color='primary'
                                variant='contained'
                                size='large'
                                component='a'
                                href={organizedData.candidateResume}
                                target='_blank'
                                download
                                startIcon={<Icons
                                    src={DownloadIcon}
                                    sx={{ height: '25px', width: '25px' }}
                                />}
                                sx={{
                                    padding: {
                                        xs:'13px 18px',
                                        sm: '13px 30px'
                                    }
                                }}
                                label={'Download Resume'}
                            />
                        </Box>
                    )}

                    <Box container>
                        {(responsiveBreakpoint === 'mob' || responsiveBreakpoint === 'tab') && (
                            <Divider
                                sx={{
                                    marginTop: '30px',
                                    marginBottom: '30px',
                                    borderColor: variables.CYAN
                                }}
                            />
                        )}

                        <Box sx={{mt: '30px'}}>
                            <Typography variant='h3' className='small-size-18px strong extrabold'>
                                {isCandidateProfile ? 'Your Preferences' : 'Employee Benefits'}
                            </Typography>
                        </Box>
                        <Box>
                            {organizedData.benefits && organizedData.benefits.length > 0
                                ? organizedData.benefits.map(benefit => benefit.name !== 'Others' ? (
                                      <StyledBenefitsChips
                                          label={benefit.name}
                                          variant='outlined'
                                          sx={{
                                              fontSize: '15px',
                                              fontWeight: '400'
                                          }}
                                      />
                                  ) : benefit.name === 'Others' &&
                                  organizedData.otherBenefits &&
                                  organizedData.otherBenefits !== '' ? otherBenefitsChips(organizedData.otherBenefits) : 'N/A'
                                )
                                : 'N/A'}
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item
                    md={7}
                    sm={12}
                    xs={12}
                    lg={8}
                    xl={8}
                    sx={{
                        my: {
                            xs: '20px',
                            md: '0px'
                        }
                    }}
                >
                    {userRole === 'user' ? (
                        <CandidateProfile
                            isEdit={isMyProfilePage}
                            candidate={candidate}
                            isAnonyomusProfile={isAnonyomusProfile}
                            isUserInvitedToApply={isUserInvitedToApply}
                            isUserApplied={isUserApplied}
                            currentUserRole={currentUserRole}
                            userId={organizedData.userId}
                        />
                    ) : (
                        <CompanyProfile
                            isEdit={isMyProfilePage}
                            company={company}
                            refetch={refetchUserDetail}
                        />
                    )}
                </Grid>
            </Grid>
            {isSuccess ? <StatusBox statusText={successMessage} /> : null}
        </Container>
    )
}

export default Profile
