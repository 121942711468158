import { Box, Grid, styled, Typography, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import {
    StyledIcon,
    StyledIconBox,
    StyledIcons,
    StyledListRow,
    TableSubHeading
} from '../../../base/commonStyles'
import DeleteIcon from '../../../assets/icons/DELETE.svg'
import Industries from '../../../assets/icons/INDUSTRY.svg'
import Location from '../../../assets/icons/LOCATION.svg'
import Department from '../../../assets/icons/DEPARTMENT.svg'
import Arrow from '../../../assets/icons/ARROW.svg'
import EDITICON from '../../../assets/icons/EDIT.svg'
import variables from '../../../settings/_variables.scss'
import { Link } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import Button from '../../Button'

import Loader from '../../../components/Loader'
import {
    getCompanyIndustries,
    getEmployeesLabel,
    getStringFromArray,
    urlSlug
} from '../../../utils/commonFunctions'
import Icons from '../../Icons'
import { useSelector } from 'react-redux'
import DeleteEntityBox from '../../DeleteEntityBox'
import StatusBox from '../../StatusBox'
import employee from '../../../assets/icons/employee.svg'
import DefaultCompanyLogo from '../../molecules/DefaultCompanyLogo'

const StyledCompanyName = styled(Typography)`
    font-size: 18px;
    color: ${variables.BLUE};
    font-weight: 600;
    margin-bottom: 12px;
`

const DELETE_COMPANY_MUTATION = gql`
    mutation ManageCompany($id: ID, $actionType: String!) {
        manageCompany(id: $id, actionType: $actionType) {
            actionType
        }
    }
`

const CompanyRow = props => {
    const { userRole, listAccess } = props
    const [isSuccess, setIsSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [deleteJob, setDeleteJobModal] = useState(false)
    const [deleteMutation, { loading, data, error }] = useMutation(DELETE_COMPANY_MUTATION, {
        onCompleted(data) {
            setDeleteJobModal(false)
            setSuccessMessage('Company Deleted Successfully!')
            setIsSuccess(true)
            setTimeout(() => {
                setIsSuccess(false)
                props.refetch()
            }, 3000)
        },
        onError(error) {
            console.log(error, 'error')
        }
    })

    const handleDeleteClose = () => {
        setDeleteJobModal(false)
    }

    const { isUserAuthenticated } = useSelector(state => state.login)
    const compaynHqLocation =
        props.data && props.data.states.length > 0 ? getStringFromArray(props.data.states) : ''
    const companyIndustries =
        props.data && props.data.industries.length > 0
            ? getCompanyIndustries(props.data.industries, props.data.otherIndustry) 
            : ''

    const companyStrength =
        props.data && props.data.noOfEmployees ? getEmployeesLabel(props.data.noOfEmployees) : null
    return (
        <StyledListRow maxWidth={false} disableGutters>
            <Grid
                container
                alignItems='center'
                spacing={3}
                
            >
                <Grid item xs={12} sm={3} md={2} alignSelf='center'>
                    <DefaultCompanyLogo
                        imgSrc={props.data.companyLogoUrl ? props.data.companyLogoUrl : null}
                    />
                </Grid>
                <Grid item xs={12} sm={['admin'].includes(userRole) ? 7 : 9} md={6}>
                    <Box
                        sx={{
                            marginTop: '30px',
                            wordBreak: 'break-word'
                        }}
                    >
                        <Typography variant='h3' className='small-size-18px strong' sx={{mb:2}}>
                           <Link to={`/company-details/${urlSlug(props.data.companyName)}/${props.data.id}`}>{props.data.companyName}</Link>
                        </Typography>
                        <Box>
                            {!['admin'].includes(userRole) ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        my: 1
                                    }}
                                >
                                    <StyledIcons component='img' src={Location} />
                                    <Typography>{compaynHqLocation}</Typography>
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        my: 1
                                    }}
                                >
                                    <StyledIcons component='img' src={Industries} />
                                    <Typography>{companyIndustries }</Typography>
                                </Box>
                            )}
                            {!['admin'].includes(userRole) ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        my: 1
                                    }}
                                >
                                    <StyledIcons component='img' src={employee} />
                                    <Typography>{companyStrength}</Typography>
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        my: 1
                                    }}
                                >
                                    <StyledIcons component='img' src={Location} />
                                    <Typography>{compaynHqLocation}</Typography>
                                </Box>
                            )}
                            {!['admin'].includes(userRole) && isUserAuthenticated ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        my: 1
                                    }}
                                >
                                    <Typography>
                                        <Typography component='span' sx={{ fontWeight: '600' }}>
                                            {props.data.jobCount}
                                        </Typography>{' '}
                                        open positions
                                    </Typography>
                                </Box>
                            ) : null}
                        </Box>
                        
                    </Box>
                </Grid>
                {['admin'].includes(userRole) ? (
                    <Grid item xs={12} sm={2} md={1} >
                        <Typography
                            variant='h3'
                            className='small-size-18px'
                        >
                            <strong>{props.data.jobCount}</strong> jobs
                        </Typography>{' '}
                        
                    </Grid>
                ) : null}

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={['admin'].includes(userRole) ? 3 : 4}
                    sx={{
                        textAlign: {
                            xs: 'center',
                            sm: 'center',
                            md: 'right',
                            lg: 'right',
                            xl: 'right'
                        }
                    }}
                >
                    {listAccess === 'admin' ? (
                        <Box sx={{ display: 'flex', width: '100%', justifyContent:'center' }}>
                            <Tooltip title='Add'>
                                <Link
                                    to={'/admin-portal/add-job/' + props.data.id}
                                    state={{ companyName: props.data.companyName }}
                                >
                                    <StyledIconBox
                                        sx={{
                                            background: variables.CYAN
                                        }}
                                    >
                                        <StyledIcon component='img' src={Department} sx={{}} />
                                    </StyledIconBox>
                                </Link>
                            </Tooltip>
                            <Tooltip title='Edit'>
                                <Link to={'/admin-portal/edit-company/' + props.data.id}>
                                    <StyledIconBox
                                        sx={{
                                            background: '#fff',
                                            marginLeft: '15px'
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: 'relative',
                                                top: '5px',
                                                color: variables.BLUE
                                            }}
                                        >
                                            <Icons
                                                src={EDITICON}
                                                sx={{
                                                    height: '30px',
                                                    width: '30px'
                                                }}
                                            />
                                        </Box>
                                    </StyledIconBox>
                                </Link>
                            </Tooltip>
                            <Tooltip title='Delete'>
                                <StyledIconBox
                                    sx={{
                                        background: `${variables.ORANGE}`,
                                        marginLeft: '15px'
                                    }}
                                    onClick={() => {
                                        setDeleteJobModal(true)
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            top: '25%',
                                            color: '#ffffff'
                                        }}
                                    >
                                        <Box
                                            component='img'
                                            src={DeleteIcon}
                                            sx={{ height: '20px' }}
                                        />
                                    </Box>
                                </StyledIconBox>
                            </Tooltip>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                textAlign: 'center'
                            }}
                        >
                            <Button
                                label={
                                    <Box component='span'>
                                        <Typography
                                            component='span'
                                            sx={{
                                                fontSize: '17px'
                                            }}
                                        >
                                            more information
                                        </Typography>
                                        <Box
                                            component='img'
                                            src={Arrow}
                                            sx={{
                                                marginLeft: '8px'
                                            }}
                                        />
                                    </Box>
                                }
                                component={Link}
                                to={`/company-details/${urlSlug(props.data.companyName)}/${props.data.id}`}
                                color='primary'
                                variant='contained'
                                size='large'
                                sx={{
                                    padding: '13px 30px',
                                    fontSize: '14px',
                                    fontWeight: '600'
                                }}
                            />
                        </Box>
                    )}
                </Grid>
            </Grid>
            {loading && <Loader />}
            {isSuccess ? <StatusBox statusText={successMessage} /> : null}
            <DeleteEntityBox
                open={deleteJob}
                deleteMutation={deleteMutation}
                entityId={props.data.id}
                deleteLoading={loading}
                closeDelete={handleDeleteClose}
            />
        </StyledListRow>
    )
}

export default CompanyRow
