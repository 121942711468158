import { Avatar, Box, Collapse, MenuItem, styled, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import HomeIcon from '../../../assets/icons/home.svg'
import MenuIcon from '../../../assets/icons/BURGER.svg'
import JobsIcon from '../../../assets/icons/DEPARTMENT.svg'
import UsersIcon from '../../../assets/icons/employee.svg'

 
import CompaniesIcon from '../../../assets/icons/COMPANIES.svg'
import BuntonIcon from '../../../assets/icons/BUNTON.svg'
import BuntonIcon2 from '../../../assets/images/MiniLogo.svg'
import ContactIcon from '../../../assets/icons/CONTACT.svg'
import variables from '../../../settings/_variables.scss'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from '../../../components'
import { useSelector } from 'react-redux'
import { truncateDetails } from '../../../utils/commonFunctions'
import { getAvatrURL, getImageURL } from '../../../utils/utilities'
import DefaultProfilIcon from '../../../assets/images/avatar.png'
import AddIcon from '../../../assets/icons/NEEW.svg'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { HomePageButtons } from '../../../pages/HomePage'
import LanguageSwitcher from '../../../components/molecules/LanguageSwitcher'
import SettingsIcon from '../../../assets/icons/settings.svg'

const NavMenuIcon = styled(Box)`
    width: 20px;
    height: 20px;
`

const MobileMenu = props => {
    const { t } = useTranslation()
    const [menuOpen, setMenuOpen] = useState(false)
    const navigate = useNavigate()
    const { isUserAuthenticated, loginCheckAuthDetails, loginDetails } = useSelector(
        state => state.login
    )
    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null

    const userId =
        loginCheckAuthDetails && loginCheckAuthDetails.id
            ? loginCheckAuthDetails.id
            : loginDetails && loginDetails.id
            ? loginDetails.id
            : null

    const publicOptions = [
        {
            menuItem: t('menu.options.home'),
            link: '/',
            menuIcon: <NavMenuIcon component='img' src={HomeIcon} />
        },
        {
            menuItem: t('menu.options.jobs'),
            link: '/job-list',
            menuIcon: <NavMenuIcon component='img' src={JobsIcon} />
        },
        {
            menuItem: t('menu.options.companies'),
            link: '/companies',
            menuIcon: <NavMenuIcon component='img' src={CompaniesIcon} />
        },
        {
            menuItem: t('menu.options.aboutus'),
            link: '/about',
            menuIcon: <NavMenuIcon component='img' src={BuntonIcon} />
        },
        {
            menuItem: t('menu.options.contactus'),
            link: '/contact-us',
            menuIcon: <NavMenuIcon component='img' src={ContactIcon} />
        }
    ]

    const candidateOptions = [
        {
            menuItem: t('menu.options.dashboard'),
            link: '/',
            menuIcon: <NavMenuIcon component='img' src={HomeIcon} />
        },
        {
            menuItem: t('menu.options.jobs'),
            link: '/job-list',
            menuIcon: <NavMenuIcon component='img' src={JobsIcon} />
        },
        {
            menuItem: t('menu.options.companies'),
            link: '/companies',
            menuIcon: <NavMenuIcon component='img' src={CompaniesIcon} />
        },
        {
            menuItem: t('menu.options.settings'),
            link: `/settings`,
            menuIcon: <NavMenuIcon component='img' src={SettingsIcon} />
        },

        {
            menuItem: t('menu.options.aboutus'),
            link: '/about',
            menuIcon: <NavMenuIcon component='img' src={BuntonIcon} />
        },
        {
            menuItem: t('menu.options.contactus'),
            link: '/contact-us',
            menuIcon: <NavMenuIcon component='img' src={ContactIcon} />
        }
    ]

    const employerOptions = [
        {
            menuItem: t('menu.options.dashboard'),
            link: '/',
            menuIcon: <NavMenuIcon component='img' src={HomeIcon} />
        },
        {
            menuItem: t('menu.options.jobs'),
            link: '/job-list',
            menuIcon: <NavMenuIcon component='img' src={JobsIcon} />
        },
        {
            menuItem: t('menu.options.settings'),
            link: `/settings`,
            menuIcon: <NavMenuIcon component='img' src={SettingsIcon} />
        },
        {
            menuItem: t('menu.options.aboutus'),
            link: '/about',
            menuIcon: <NavMenuIcon component='img' src={BuntonIcon} />
        },
        {
            menuItem: t('menu.options.contactus'),
            link: '/contact-us',
            menuIcon: <NavMenuIcon component='img' src={ContactIcon} />
        }
    ]

    const adminOptions = [
        {
            menuItem: t('menu.options.dashboard'),
            link: '/admin-portal',
            menuIcon: <NavMenuIcon component='img' src={HomeIcon} />
        },
        {
            menuItem: t('menu.options.companies'),
            link: '/admin-portal/companies',
            menuIcon: <NavMenuIcon component='img' src={CompaniesIcon} />
        },
        {
            menuItem: 'Users',
            traslationKey: 'users',
            link: '/admin-portal/users',
            menuIcon: <NavMenuIcon component='img' src={UsersIcon} />
        },
        {
            menuItem: t('menu.options.newcompany'),
            link: '/admin-portal/add-company',
            menuIcon: <NavMenuIcon component='img' src={AddIcon} />
        },
        {
            menuItem: t('menu.options.jobs'),
            link: '/admin-portal/job-list',
            menuIcon: <NavMenuIcon component='img' src={JobsIcon} />
        },
        {
            menuItem: t('menu.options.newjobs'),
            link: '/admin-portal/add-job',
            menuIcon: <NavMenuIcon component='img' src={AddIcon} />
        },
        {
            menuItem: t('menu.options.settings'),
            link: '/admin-portal/settings',
            menuIcon: <NavMenuIcon component='img' src={AddIcon} />
        }
    ]

    const options = !isUserAuthenticated
        ? publicOptions
        : userRole === 'user'
        ? candidateOptions
        : userRole === 'employer'
        ? employerOptions
        : userRole === 'admin'
        ? adminOptions
        : publicOptions
    return (
        <Box>
            <Box className='mobile-navigation' component='div'>
                <Box
                    aria-haspopup='true'
                    onClick={() => setMenuOpen(true)}
                    className='navigation--menu_icontext_container'
                    sx={{
                        ...props.containerSx
                    }}
                >
                    <Box
                        component='img'
                        src={MenuIcon}
                        className={props.responsiveMenu ? '' : 'mobile-navigation--menu_icon'}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    position: 'fixed',
                    left: '0',
                    textAlign: 'center',
                    background: variables.CYAN,
                    top: '0'
                }}
            >
                <Collapse sx={menuOpen ? { height: '100vh !important' } : {}} in={menuOpen}>
                    <Box
                        sx={{
                            padding: '30px',
                            maxHeight: '90vh',
                            overflow: 'auto'
                        }}
                    >
                        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                            <Box sx={{width: 'fit-content'}}>
                                <LanguageSwitcher
                                    sx={{
                                        mr: 0,
                                        p: '4px',
                                        minWidth: 'fit-content',
                                    }}
                                    flagSx={{
                                        height: '33px',
                                        width: '33px'
                                    }}
                                />
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                <Typography variant='h3' className='section-heading strong notranslate'>
                                    {t('menu.title')}
                                </Typography>
                            </Box>
                            <Box sx={{ float: 'right', width: 'fit-content' }} onClick={() => setMenuOpen(false)}>
                                <CloseIcon sx={{ color: variables.BLUE, fontSize: '40px' }} />
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                // margin: '50px 0px',
                                margin: '45px auto'
                            }}
                        >
                            {options.map((option, index) => (
                                <MenuItem
                                    key={index}
                                    sx={{
                                        // margin: '50px 0px',
                                        padding: '20px',
                                        textAlign: 'left',
                                        // textAlign: 'center',

                                        fontSize: '18px',
                                        width: '100%',
                                        margin: '0 auto'
                                    }}
                                >
                                    <Button
                                        component={Link}
                                        to={option.link}
                                        reloadDocument
                                        sx={{ width: '100%', p: 0, color: variables.BLUE, justifyContent: 'left' }}
                                        startIcon={option.menuIcon}
                                        label={<Typography sx={{
                                                ml: 2,
                                                width: 'fit-content',
                                                fontSize: '18px',
                                                color: variables.BLUE
                                            }}>{option.menuItem}</Typography>}
                                    />
                                </MenuItem>
                            ))}
                        </Box>
                        {isUserAuthenticated ? (
                            <HomePageButtons
                                label={
                                    <Typography variant='h3' className='strong small-size-18px'>
                                        {truncateDetails(loginCheckAuthDetails.name, 16)}
                                    </Typography>
                                }
                                component={Link}
                                to={userRole !== 'admin' ? '/my-profile' : null}
                                color='primary'
                                variant='contained'
                                size='large'
                                sx={{
                                    py: 2,
                                    px: 4,
                                    textTransform: 'none',
                                    background: variables.WHITE1,
                                    alignItems: 'center'
                                }}
                                startIcon={
                                    <Avatar
                                        sx={{
                                            backgroundColor: '#fff',
                                            height: '50px',
                                            width: '50px',
                                            objectFit: 'contain'
                                        }}
                                        alt='Natacha'
                                        src={
                                            ['user', 'employer'].includes(userRole)
                                                ? loginCheckAuthDetails.profileImage
                                                    ? userRole === 'employer'
                                                        ? getImageURL(
                                                              loginCheckAuthDetails.profileImage
                                                          )
                                                        : userRole === 'user'
                                                        ? getAvatrURL(
                                                              loginCheckAuthDetails.profileImage
                                                          )
                                                        : DefaultProfilIcon
                                                    : DefaultProfilIcon
                                                : BuntonIcon2
                                        }
                                    />
                                }
                            />
                        ) : null}
                        {!isUserAuthenticated ? (
                            <>
                                <Box>
                                    <Button
                                    label={<Typography
                                            variant='h3'
                                            className='strong small-size-18px'
                                            sx={{
                                                color: `${variables.BLUE} !important`
                                            }}
                                        >
                                            {t('login')}
                                        </Typography>}
                                        component={Link}
                                        to='/login'
                                        color='secondary'
                                        variant='contained'
                                        size='large'
                                        sx={{
                                            py: 2,
                                            px: 4,
                                            background: variables.WHITE1
                                        }}
                                    />
                                </Box>
                                <Box sx={{mt: 5}}>
                                    <Button
                                        label={<Typography
                                            variant='h3'
                                            className='strong small-size-18px'
                                            sx={{
                                                color: `${variables.BLUE} !important`
                                            }}
                                        >
                                            {t('register')}
                                        </Typography>}
                                        component={Link}
                                        to='/registration'
                                        color='primary'
                                        variant='contained'
                                        size='large'
                                        sx={{
                                            py: 2,
                                            px: 4,
                                            background: variables.WHITE1
                                        }}
                                    />
                                </Box>
                            </>
                        ) : (
                            <Box sx={{ mt: 5 }}>
                                <Button
                                    label={
                                        <Typography
                                            variant='h3'
                                            className='strong small-size-18px'
                                            sx={{
                                                color: `${variables.WHITE1} !important`
                                            }}
                                        >
                                            {t('menu.options.logout')}
                                        </Typography>
                                    }
                                    color='primary'
                                    variant='contained'
                                    size='large'
                                    onClick={() => {
                                        navigate('/logout')
                                    }}
                                    sx={{
                                        py: 2,
                                        px: 4
                                    }}
                                />
                            </Box>
                        )}
                        <Box sx={{ textAlign: 'center', mt: 10 }}>
                            <Typography variant='h5' className='small-size-16px'>
                                {moment().year()} | Bunton GmbH
                            </Typography>
                        </Box>
                    </Box>
                </Collapse>
            </Box>
        </Box>
    )
}

export default MobileMenu
