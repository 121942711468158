import { Box, Grid, styled, Typography } from '@mui/material'
import React from 'react'
import { StyledIconBox, StyledListRow } from '../../../base/commonStyles'
import variables from '../../../settings/_variables.scss'
import Industry from '../../../assets/icons/INDUSTRY.svg'
import Department from '../../../assets/icons/DEPARTMENT.svg'
import { useState } from 'react'

import Location from '../../../assets/icons/LOCATION.svg'
import Experience from '../../../assets/icons/EXPERIENCE.svg'
import DeleteEntityBox from '../../../components/DeleteEntityBox'

import {
    getExperienceLabel,
    getStringFromArray
} from '../../../utils/commonFunctions'
import { Button } from '../../../components'
import Arrow from '../../../assets/icons/ARROW.svg'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { DELETE_JOB_MUTATION } from '../../../queries/jobPostQueries'
import Icons from '../../../components/Icons'
import EDITICON from '../../../assets/icons/EDIT.svg'
import StatusBox from '../../../components/StatusBox'
import DeleteIcon from '../../../assets/icons/DELETE.svg'
import DefaultCompanyLogo from '../../../components/molecules/DefaultCompanyLogo'
import { useTranslation } from 'react-i18next'


const StyledIcons = styled(Box)`
    height: 18px;
    width: 18px;
    margin-right: 15px;
`

const DashboardJobsContainer = props => {
    const {t} = useTranslation()
    const { jobData, refetch, listType } = props
    const [isSuccess, setIsSuccess] = useState(false)
    const [deleteJob, setDeleteJobModal] = useState(false)

    const handleDeleteClose = () => {
        setDeleteJobModal(false)
    }

    const [deleteMutation, { loading, data, error }] = useMutation(DELETE_JOB_MUTATION, {
        onCompleted(data) {
            setDeleteJobModal(false)

            setIsSuccess(true)
            setTimeout(() => {
                setIsSuccess(false)
            }, 3000)

            // refetch({afterCursor:""})
            refetch()
        },
        onError(error) {
            console.log(error, 'error')
        }
    })
    return (
        <StyledListRow
            maxWidth='xl'
            disableGutters
            sx={{
                minHeight: '160px',
                maxWidth: '100%',
                borderRadius: {
                    xs: '50px',
                    md: '80px'
                }
            }}
        >
            <Grid container sx={{
                    p: { xs: 1, md: 2 }
                }}
                columnSpacing={{
                    xs: 0,
                    sm: props.employDashboard ? 2 : 0,
                    md: 2
                }}
                rowSpacing={{ xs: 2, sm: props.employDashboard ? 0 : 2, md: 0 }}
                alignItems='center'
            >
                <Grid item xs={12}
                    sm={3}
                    md={2}
                    lg={1.75}
                    xl={1.5}
                >
                    <DefaultCompanyLogo
                        imgSrc={jobData && jobData.companyLogoUrl ? jobData.companyLogoUrl : null}
                        sx={{
                            borderRadius: '80px',
                            height: {
                                xs: '80px',
                                sm: '80px',
                                lg: '110px'
                            },
                            width: {
                                xs: '80px',
                                sm: '80px',
                                lg: '110px'
                            }
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6.5}
                    lg={7.75}
                    xl={8}
                >
                    <Box>
                        <Typography
                            variant='h5' className='small-size'
                        >
                            {jobData && jobData.companyName ? jobData.companyName : ''}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant='h3' className='strong'>
                            {jobData && jobData.jobTitle ? jobData.jobTitle : ''}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            mt: 1,
                            display: {
                                xs: 'block',
                                lg: 'flex'
                            }
                        }}
                    >
                        {jobData && jobData.industries ? (
                            <Box sx={{ display: 'flex', alignItems: 'start' }}>
                                <StyledIcons component='img' src={Industry} />
                                <Typography>
                                    {getStringFromArray(jobData.industries, jobData.otherIndustry)}
                                </Typography>
                            </Box>
                        ) : null}
                        {listType !== 'likedJobs' && jobData && jobData.states ? (
                            <Box sx={{
                                display: 'flex',
                                mt: { xs: 1, lg: 0 },
                                ml: { xs: 0, lg: 2 },
                                alignItems: 'start'
                            }}>
                                <StyledIcons component='img' src={Location} />
                                <Typography>{getStringFromArray(jobData.states)}</Typography>
                            </Box>
                        ) : null}
                        {jobData && jobData.departments ? (
                            <Box sx={{
                                display: 'flex',
                                mt: { xs: 1, lg: 0 },
                                ml: { xs: 0, lg: 2 },
                                alignItems: 'start'
                            }}>
                                <StyledIcons component='img' src={Department} />
                                <Typography>
                                    {getStringFromArray(jobData.departments, jobData.otherDepartment)}
                                </Typography>
                            </Box>
                        ) : null}

                        {listType !== 'likedJobs' && jobData && jobData.experienceRequired ? (
                            <Box sx={{
                                display: 'flex',
                                mt: { xs: 1, lg: 0 },
                                ml: { xs: 0, lg: 2 },
                                alignItems: 'start'
                            }}>
                                <StyledIcons component='img' src={Experience} />
                                <Typography>
                                    {getExperienceLabel(jobData.experienceRequired)}
                                </Typography>
                            </Box>
                        ) : null}
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={3}
                    md={3.5}
                    lg={2.5}
                    xl={2.5}
                    sx={{
                        textAlign: {
                            xs: 'center',
                            md: 'right'
                        }
                    }}
                >
                    {props.employDashboard ? (
                        <Box
                            sx={{
                                display: 'flex',
                                float: 'right',
                                marginRight: '30px'
                            }}
                        >
                            <Link to={'/edit-job/' + jobData?.id}>
                                <StyledIconBox
                                    sx={{
                                        background: '#fff',
                                        marginLeft: '15px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            top: '5px',
                                            color: variables.BLUE
                                        }}
                                    >
                                        <Icons
                                            src={EDITICON}
                                            sx={{
                                                height: '30px',
                                                width: '30px'
                                            }}
                                        />
                                    </Box>
                                </StyledIconBox>
                            </Link>
                            <StyledIconBox
                                sx={{
                                    background: `${variables.ORANGE}`,
                                    marginLeft: '15px'
                                }}
                                onClick={() => {
                                    setDeleteJobModal(true)
                                }}
                            >
                                <Box
                                    sx={{
                                        position: 'relative',
                                        top: '25%',
                                        color: '#ffffff'
                                    }}
                                >
                                    <Box component='img' src={DeleteIcon} sx={{ height: '20px' }} />
                                </Box>
                            </StyledIconBox>
                            <Link to={'/job-details/' + jobData.id}>
                                <StyledIconBox
                                    sx={{
                                        background: variables.BLUE,
                                        marginLeft: '15px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            top: '25%',
                                            color: '#ffffff'
                                        }}
                                    >
                                        <Box component='img' src={Arrow} />
                                    </Box>
                                </StyledIconBox>
                            </Link>
                        </Box>
                    ) : (
                        <Box>
                            <Button
                                label={
                                    <Typography
                                        variant='h5'
                                        className='small-size-strong extrabold'
                                        sx={{
                                            color: `${variables.WHITE1} !important`,
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        {t('buttons.moreinfo')}
                                    </Typography>
                                }
                                component={Link}
                                to={'/job-details/' + jobData.id}
                                color='primary'
                                variant='contained'
                                size='large'
                                sx={{
                                    padding: '13px 30px',
                                    fontSize: '14px',
                                    fontWeight: '600'
                                }}
                            />
                        </Box>
                    )}
                </Grid>
            </Grid>
            {isSuccess ? <StatusBox top={'3%'} statusText='Job Post Deleted!' /> : null}

            <DeleteEntityBox
                open={deleteJob}
                deleteMutation={deleteMutation}
                entityStatus={jobData.status}
                entityId={jobData.id}
                deleteLoading={loading}
                closeDelete={handleDeleteClose}
            />
        </StyledListRow>
    )
}

export default DashboardJobsContainer
