/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react'
import { Box, Typography } from '@mui/material'
import { Button } from '../../../components'
import Icons from '../../../components/Icons'
import { BUTTON_DATA, keys, TextStrings } from '../../../constants'
import BACK from '../../../assets/icons/BACK.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PLUS_BLACK from '../../../assets/icons/PLUS_BLACK.svg'
import { styles } from './styles'
import Layout from '../../../template/Layout'
import variables from '../../../settings/_variables.scss'

import EducationRow from './educationRow'
import ExperienceRow from './experienceRow'

const ProfileSetupStepTwo = props => {
    const navigate = useNavigate()

    let { key } = useParams()
    let buttonDetails = BUTTON_DATA.find(o => o.key === key)
    const { profileDetail } = useSelector(state => state.profileDetail)
    
    const isEducation = key === keys.education

    return (
        <Layout>
            <Box sx={styles.container}>
                <Box sx={styles.header}>
                    <Typography variant='subtitle1' gutterBottom sx={styles.title}>
                        {buttonDetails?.label}
                    </Typography>
                    <Box
                        sx={styles.backIconContainer}
                        onClick={() => {
                            navigate(-1)
                        }}
                    >
                        <Icons src={BACK} />
                    </Box>
                </Box>
                <Box sx={styles.formContainer}>
                    <Button
                        label={`${TextStrings.add} ${
                            isEducation ? buttonDetails?.label.toLowerCase() : TextStrings.aPosition
                        }`}
                        color='secondary'
                        variant='contained'
                        size='large'
                        onClick={() => {
                            isEducation
                                ? navigate(`/profileSetup/EducationDetail`)
                                : navigate(`/profileSetup/ExperienceDetail`)
                        }}
                        sx={styles.button}
                        startIcon={<Icons src={PLUS_BLACK} />}
                    />
                    {profileDetail[key] &&
                        profileDetail[key].map(elem => {
                            if (isEducation) {
                                return <EducationRow elem={elem} />
                            } else {
                                return <ExperienceRow elem={elem} />
                            }
                        })}
                </Box>
            </Box>
        </Layout>
    )
}

export default ProfileSetupStepTwo
