import { Box, FormHelperText } from '@mui/material'
import React from 'react'
import { StyleInputLabels } from '../../base/commonStyles'

const CustomFormLabel = props => {
    const { formLabel, formFieldError } = props
    return (
        <Box sx={{ display: 'block', mt: 6, mb: 2, ml: 4, ...props.labelSx }}>
            <StyleInputLabels >{formLabel}</StyleInputLabels>
            {formFieldError && (
                <FormHelperText id='my-helper-text' error={true} sx={{ mt: 0 }}>
                    <strong>{formFieldError}</strong>
                </FormHelperText>
            )}
        </Box>
    )
}

export default CustomFormLabel
