import { ThemeProvider } from '@emotion/react'
import {
    Box,
    createTheme,
    FormControl,
    getOffsetLeft,
    InputAdornment,
    MenuItem,
    Select,
    styled
} from '@mui/material'
import React from 'react'
import DownArrow from '../../assets/icons/ARROW DOWN.svg'

import variables from '../../settings/_variables.scss'
import { truncateDetails } from '../../utils/commonFunctions'

const theme = createTheme({
    components: {
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    zIndex: '2'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {}
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    display: 'none'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    paddingRight: '0px !important',
                    paddingLeft: '15px',
                    fontSize: '18px',
                    color: variables.BLUE
                },
                notchedOutline: {
                    border: '3px solid !important',
                    borderColor: `${variables.CYAN} !important`,
                    borderRadius: '50px !important'
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    paddingLeft: '30px'
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    width: {
                        md: '290px',
                        lg: '290px',
                        xl: '290px'
                    },
                    maxHeight: '290px !important',
                    // borderRadius: '30px',
                    border: `3px solid ${variables.CYAN}`,
                    borderTop: '0',
                    boxShadow: 'none'
                }
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                positionStart: {
                    marginLeft: '30px'
                },
                positionEnd: {
                    position: 'relative',
                    right: '30px',
                    zIndex: '1'
                }
            }
        }
    }
})

const StyledMenuItems = styled(MenuItem)`
    padding: 6px 30px;
    text-align: right;
    font-size: 18px;
    color: ${variables.BLUE};
`

const DropDown = props => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [dropdownOpen, setDropdownOpen] = React.useState(false)
    const handleClose = () => {
        setAnchorEl(null)
        setDropdownOpen(false)
    }
    return (
        <ThemeProvider theme={theme}>
            <FormControl
                sx={{
                    ...props.formControllerSx,
                    m: 0,
                    zIndex: 1,
                    marginTop: '0px',
                    background: variables.WHITE1,
                    borderRadius: '50px'
                }}
            >
                <Select
                    variant='outlined'
                    onChange={props.onChange}
                    onClose={handleClose}
                    onOpen={e => {
                        setAnchorEl(e.currentTarget)
                        setDropdownOpen(true)
                    }}
                    displayEmpty={true}
                    className='dropdown'
                    defaultValue={props.lab}
                    placeholder={props.placeholder}
                    value={props.value !== '' ? props.value : props.lab}
                    renderValue={value => {
                        if (props.options && props.options.length > 0) {
                            const item = props.options.filter(
                                val => val.id === value || val.value === value
                            )
                            return (
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <Box sx={{ position: 'relative', margin: '0px 5px 0px 10px' }}>
                                        {props.startAdornment}
                                    </Box>
                                    {/* {item[0]
                                        ? truncateDetails(
                                              item[0].name ? item[0].name : item[0].label
                                          )
                                        : value} */}
                                    {props.value
                                        ? item[0]
                                            ? truncateDetails(
                                                  item[0].name ? item[0].name : item[0].label,
                                                  19
                                              )
                                            : value
                                        : props.lab}
                                </Box>
                            )
                        }
                    }}
                    IconComponent={() => (
                        <InputAdornment position='end'>
                            <Box sx={{ position: 'relative' }}>
                                <Box
                                    component='img'
                                    src={DownArrow}
                                    className={dropdownOpen ? 'dropdown--icon_open' : ''}
                                />{' '}
                            </Box>
                        </InputAdornment>
                    )}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                <Box sx={{ position: 'relative' }}>
                                    <Box component='img' src={DownArrow} />{' '}
                                </Box>
                            </InputAdornment>
                        ),
                        startAdornment: (
                            <InputAdornment position='start'>
                                <Box sx={{ position: 'relative' }}>{props.startAdornment}</Box>
                            </InputAdornment>
                        )
                    }}
                    MenuProps={{
                        anchorOrigin: {
                            // vertical: getOffsetLeft(anchorEl) - 2,
                            horizontal: getOffsetLeft(anchorEl) + 170
                        },
                        // transformOrigin: {
                        //     vertical: 'top',
                        //     horizontal: 'right'
                        // },
                        PaperProps: {
                            sx: {
                                maxWidth: {
                                    md: 200,
                                    lg: 265,
                                    xl: 290
                                },
                                width: {
                                    md: 200,
                                    lg: 265,
                                    xl: 290
                                },
                                borderRadius: '30px',
                                // borderTopLeftRadius: '0px',
                                // borderTopRightRadius: '0px',

                                border: `3px solid ${variables.CYAN}`,
                                // borderTop: '0px',
                                backgroundColor: variables.WHITE1,
                                paddingTop: '20px',
                                paddingRight: '8px !important',
                                paddingBottom: '20px'
                            }
                        },
                        MenuListProps: {
                            sx: {
                                maxHeight: '250px !important',
                                overflow: 'hidden',
                                overflowY: 'scroll'
                            }
                        }
                        // transitionDuration: { appear: 1000, enter: 1000, exit: 0 }
                    }}
                >
                    <StyledMenuItems
                        disabled
                        value=''
                        sx={{
                            width: '290px',
                            opacity: '1 !important',
                            color: 'rgba(0, 0, 0, 0.16)',
                            borderRadius: '30px',
                            backgroundColor: variables.WHITE1,
                            padding: '14px 30px'
                        }}
                    >
                        {props.placeholder}
                        <InputAdornment
                            position='end'
                            sx={{
                                right: {
                                    md: '50px',
                                    lg: '40px',
                                    xl: '30px'
                                },
                                position: 'absolute'
                            }}
                        >
                            <Box>
                                <Box
                                    component='img'
                                    src={DownArrow}
                                    className={dropdownOpen ? 'dropdown--icon_open' : ''}
                                />{' '}
                            </Box>
                        </InputAdornment>
                    </StyledMenuItems>

                    {props.options &&
                        props.options.map((option, index) =>
                            option.id ? (
                                <StyledMenuItems value={option.id}>{option.name}</StyledMenuItems>
                            ) : (
                                <StyledMenuItems value={option.value}>
                                    {option.label}
                                </StyledMenuItems>
                            )
                        )}
                </Select>
            </FormControl>
        </ThemeProvider>
    )
}

export default DropDown
