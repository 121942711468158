import { Box, Chip, CircularProgress, Grid, styled, Typography } from '@mui/material'
import React, { useState } from 'react'
import { StyledListRow } from '../../../base/commonStyles'
import { Link } from 'react-router-dom'
import variables from '../../../settings/_variables.scss'

import Industry from '../../../assets/icons/INDUSTRY.svg'
import Location from '../../../assets/icons/LOCATION.svg'
import Department from '../../../assets/icons/DEPARTMENT.svg'

import Button from '../../Button'
import { useSelector } from 'react-redux'
import Arrow from '../../../assets/icons/ARROW.svg'
import { getCompanyIndustries } from '../../../utils/commonFunctions'
import { getStringFromArray } from '../../../utils/commonFunctions'

import { truncateDetails } from '../../../utils/commonFunctions'
import { useMutation } from '@apollo/client'
import { APPLY_JOB_MUTATION, DELETE_JOB_MUTATION } from '../../../queries/jobPostQueries'
import Icons from '../../Icons'
import EDITICON from '../../../assets/icons/EDIT.svg'
import DeleteIcon from '../../../assets/icons/DELETE.svg'

import StatusBox from '../../StatusBox'
import DeleteEntityBox from '../../DeleteEntityBox'
import ApplyIcon from '../../../assets/icons/ApplyIcon.svg'
import StarIcon from '@mui/icons-material/Star'
import { useTranslation } from 'react-i18next'
import { HomePageButtons } from '../../../pages/HomePage'
import DefaultCompanyLogo from '../../molecules/DefaultCompanyLogo'
import LikeButton from '../../molecules/LikeButton'

const StyledIcons = styled(Box)`
    height: 18px;
    width: 18px;
    margin-right: 8px;
`

const JobListRow = props => {
    const { t } = useTranslation()
    const { loginCheckAuthDetails, loginDetails, isUserAuthenticated } = useSelector(
        state => state.login
    )
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const [applyLoading, setApplyLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const { data, refetch, userRole } = props
    
    const jobIndustriesString =
        data && data.industries
            ? truncateDetails(getCompanyIndustries(data.industries, data.otherIndustry), 50)
            : ''
    const jobDepartmentString =
        data && data.industries
            ? truncateDetails(getCompanyIndustries(data.departments, data.otherDepartment), 50)
        : ''
    const locationString =
        data && data.states ? truncateDetails(getStringFromArray(data.states), 50) : null

    const [isApplied, setIsApplied] = useState(false)
    const [deleteJob, setDeleteJobModal] = useState(false)
    const [deleteMutation, { loading: deleteLoading, data: deleteData, error: deleteError }] =
        useMutation(DELETE_JOB_MUTATION, {
            onCompleted(data) {
                setDeleteJobModal(false)
                setSuccessMessage('Job Deleted Successfully!')
                setIsSuccess(true)
                setTimeout(() => {
                    setIsSuccess(false)
                    refetch()
                }, 3000)
            },
            onError(error) {
                console.log(error, 'error')
            }
        })

    const [applyJobMutation] = useMutation(APPLY_JOB_MUTATION, {
        onCompleted(data) {
            setSuccessMessage('Applied!')
            setApplyLoading(false)
            setIsSuccess(true)
            setIsApplied(true)

            setTimeout(() => {
                setIsSuccess(false)
            }, 3000)
        }
    })
    const userId =
        loginCheckAuthDetails && loginCheckAuthDetails.id
            ? loginCheckAuthDetails.id
            : loginDetails && loginDetails.id
            ? loginDetails.id
            : null

    const handleApply = () => {
        setApplyLoading(true)
        applyJobMutation({
            variables: {
                jobId: data.id,
                userId: userId
            }
        })
    }

    const handleDeleteClose = () => {
        setDeleteJobModal(false)
    }

    const actionControlButton = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    float: { xs: 'none', md: 'right' },
                    justifyContent: { xs: 'center', md: 'right' }
                }}
            >
                <HomePageButtons
                    label=''
                    color='primary'
                    variant='contained'
                    size='large'
                    component={Link}
                    to={
                        userRole === 'admin'
                            ? '/admin-portal/edit-job/' + data.id
                            : '/edit-job/' + data.id
                    }
                    sx={{
                        background: variables.WHITE,
                        mr: 1,
                        p: `16px !important`,
                        minWidth: '50px',
                        width: '50px',
                        height: '50px',
                        '&:hover': {
                            background: variables.WHITE
                        }
                    }}
                >
                    <Box
                        component='img'
                        src={EDITICON}
                        sx={{
                            height: '30px',
                            width: '30px'
                        }}
                    />
                </HomePageButtons>
                <HomePageButtons
                    label=''
                    color='primary'
                    variant='contained'
                    size='large'
                    component={Box}
                    onClick={() => {
                        setDeleteJobModal(true)
                    }}
                    sx={{
                        background: variables.ORANGE,
                        mr: 1,
                        p: `16px !important`,
                        minWidth: '50px',
                        width: '50px',
                        height: '50px',
                        '&:hover': {
                            background: variables.ORANGE
                        }
                    }}
                >
                    <Icons
                        src={DeleteIcon}
                        sx={{
                            height: '20px',
                            width: '100%'
                        }}
                        style={{
                            height: '100%',
                            width: '100%'
                        }}
                    />
                </HomePageButtons>
                <HomePageButtons
                    label=''
                    color='primary'
                    variant='contained'
                    size='large'
                    component={Link}
                    to={'/job-details/' + props.data.id}
                    sx={{
                        background: variables.BLUE,
                        p: `16px !important`,
                        minWidth: '50px',
                        width: '50px',
                        height: '50px'
                    }}
                >
                    <Icons
                        src={Arrow}
                        sx={{
                            height: '20px',
                            width: '20px'
                        }}
                    />
                </HomePageButtons>
            </Box>
        )
    }

    return (
        <StyledListRow
            maxWidth='xl'
            disableGutters
            sx={{
                opacity: data && data.status === 'draft' ? '0.7': '1',
                minHeight: '160px',
                maxWidth: '100%',
                borderRadius: {
                    xs: '50px',
                    md: '80px'
                }
            }}
        >
            <Grid
                container
                sx={{
                    p: { xs: 1, md: 2 }
                }}
                columnSpacing={{
                    xs: 0,
                    sm: ['admin', 'employer'].includes(userRole) ? 2 : 0,
                    md: 2
                }}
                rowSpacing={{ xs: 2, sm: ['admin', 'employer'].includes(userRole) ? 0 : 2, md: 0 }}
                alignItems='center'
            >
                <Grid
                    item
                    xs={12}
                    sm={['admin', 'employer'].includes(userRole) ? 2 : 4}
                    md={['admin', 'employer'].includes(userRole) ? 1.6 : 2}
                    lg={['admin', 'employer'].includes(userRole) ? 1.5 : 1.75}
                    xl={1.5}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: {
                                xs: 'flex',
                                sm: 'block'
                            },
                            alignItems: 'center'
                        }}
                    >
                        <Box sx={{ width: { xs: '80%', sm: '100%' } }}>
                            <DefaultCompanyLogo
                                imgSrc={data && data.companyLogoUrl ? data.companyLogoUrl : null}
                                sx={{
                                    height: '70px',
                                    width: '70px'
                                }}
                            />
                        </Box>
                        {data && data.status === 'published' && (
                            <Box sx={{ mt: 1 }}>
                                <LikeButton
                                    jobPostId={data.id}
                                    userId={userId}
                                    role={userRole}
                                    totalLikes={data.likes}
                                    isLiked={data.isLiked}
                                    isUserAuthenticated={isUserAuthenticated}
                                    sx={{
                                        height: '40px',
                                        width: '70px',
                                        p: '5px'
                                    }}
                                />
                            </Box>
                        )}
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={['admin', 'employer'].includes(userRole) ? 6 : 8}
                    md={6.5}
                    lg={7.75}
                    xl={8}
                >
                    <Box>
                        <Typography variant='h5' className='small-size'>
                            {data && data.company ? data.company.companyName : ''}
                        </Typography>
                    </Box>
                    <Box sx={{display:'flex', alignItems:'center'}}>
                        <Typography variant='h3' className='strong'>
                            {data && data.jobTitle ? data.jobTitle : ''}
                        </Typography>
                        {data && data.status === 'draft' && (
                            <Chip
                                variant='status-chip'
                                label={data.status.toUpperCase()}
                                sx={{ ml: 1 }}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            mt: data && data.status === 'draft' ? 2 : 1,
                            display: {
                                xs: 'block',
                                xl: 'flex'
                            }
                        }}
                    >
                        {
                            jobDepartmentString ? (
                                <Box sx={{ display: 'flex', alignItems: 'start',mt: { xs: 1, xl: 0 } }}>
                                    <StyledIcons component='img' src={Department} />
                                    <Typography>{jobDepartmentString}</Typography>
                                </Box>
                            ) : null
                        }
                        {jobIndustriesString ? (
                            <Box sx={{ display: 'flex', alignItems: 'start',mt: { xs: 1, xl: 0 },
                            ml: { xs: 0, xl: 2 }, }}>
                                <StyledIcons component='img' src={Industry} />
                                <Typography>{jobIndustriesString}</Typography>
                            </Box>
                        ) : null}
                        {locationString ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    mt: { xs: 1, xl: 0 },
                                    ml: { xs: 0, xl: 2 },
                                    alignItems: 'start'
                                }}
                            >
                                <StyledIcons component='img' src={Location} />
                                <Typography>{locationString}</Typography>
                            </Box>
                        ) : null}
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={['admin', 'employer'].includes(userRole) ? 4 : 12}
                    md={['admin', 'employer'].includes(userRole) ? 3.9 : 3.5}
                    lg={2.5}
                    xl={2.5}
                    sx={{
                        textAlign: {
                            xs: 'center',
                            md: 'right'
                        }
                    }}
                >
                    {!['admin', 'employer'].includes(userRole) ? (
                        <Box
                            sx={{
                                width: '100%',
                                display: 'block',
                                justifyContent: {
                                    xs: 'center',
                                    sm: 'center',
                                    md: 'right'
                                }
                            }}
                        >
                            {isUserAuthenticated && userRole === 'user' ? (
                                <Box
                                    sx={{
                                        mb: '11px'
                                    }}
                                >
                                    {data.externalLink && userRole === 'user' ? (
                                        <Box>
                                            {data.externalLink ? (
                                                <Button
                                                    label={
                                                        <Typography sx={{ textAlign: 'center' }}>
                                                            View on
                                                            <br />
                                                            Company website
                                                        </Typography>
                                                    }
                                                    target='_blank'
                                                    color='secondary'
                                                    variant='contained'
                                                    size='large'
                                                    sx={{
                                                        padding: '8px 15px',
                                                        fontSize: '14px',
                                                        fontWeight: '900',
                                                        cursor: 'hand',
                                                        width: '100%',
                                                        maxWidth: '212px'
                                                    }}
                                                    href={
                                                        data.externalLink.indexOf('http://') ===
                                                            0 ||
                                                        data.externalLink.indexOf('https://') ==
                                                            /*  */ 0
                                                            ? data.externalLink
                                                            : `//${data.externalLink}`
                                                    }
                                                >
                                                    <Typography>
                                                        View on
                                                        <br />
                                                        Company website
                                                    </Typography>
                                                </Button>
                                            ) : null}
                                        </Box>
                                    ) : data.candidateApplied || isApplied ? (
                                        <Box>
                                            <Button
                                                label={
                                                    <Typography
                                                            variant='h5'
                                                            className='small-size-14px extrabold'
                                                            sx={{
                                                                color: `${variables.WHITE1} !important`,
                                                                fontWeight: 'bold'
                                                            }}
                                                        >
                                                            Applied
                                                        </Typography>}
                                                color='secondary'
                                                variant='contained'
                                                size='large'
                                                sx={{
                                                    padding: '13px 30px',
                                                    cursor: 'default',
                                                    width: '100%'
                                                }}
                                                endIcon={
                                                    <StarIcon
                                                        sx={{
                                                            ml:
                                                                responsiveBreakpoint === 'mob'
                                                                    ? 0
                                                                    : '10px',
                                                            color: 'white'
                                                        }}
                                                    />
                                                }
                                            />
                                        </Box>
                                    ) : (
                                        <Box>
                                            <Button
                                                disabled={applyLoading}
                                                label={
                                                    applyLoading ? (
                                                        <CircularProgress
                                                            size={20}
                                                            sx={{
                                                                color: variables.BLUE
                                                            }}
                                                        />
                                                    ) : (
                                                        <Typography
                                                            variant='h5'
                                                            className='small-size-14px extrabold'
                                                            sx={{
                                                                color: `${variables.BLUE} !important`,
                                                                fontWeight: 'bold'
                                                            }}
                                                        >
                                                            Apply
                                                        </Typography>
                                                    )
                                                }
                                                color='secondary'
                                                variant='contained'
                                                size='large'
                                                sx={{
                                                    padding: '13px 30px',
                                                    fontSize: '14px',
                                                    fontWeight: '900',
                                                    width: '100%'
                                                }}
                                                endIcon={
                                                    <Icons
                                                        src={ApplyIcon}
                                                        sx={{
                                                            width: '24px',
                                                            height: '24px'
                                                        }}
                                                    />
                                                }
                                                onClick={handleApply}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            ) : null}
                            <Box>
                                <Button
                                    label={
                                        <Typography
                                            variant='h5'
                                            className='small-size-14px extrabold'
                                            sx={{
                                                color: `${variables.WHITE1} !important`,
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {t('buttons.moreinfo')}
                                        </Typography>
                                    }
                                    component={Link}
                                    to={'/job-details/' + data.id}
                                    color='primary'
                                    variant='contained'
                                    size='large'
                                    sx={{
                                        padding: '13px 23px',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        width: '100%'
                                    }}
                                />
                            </Box>
                        </Box>
                    ) : ['admin', 'employer'].includes(userRole) ? (
                        actionControlButton()
                    ) : null}
                </Grid>
            </Grid>
            {isSuccess ? <StatusBox statusText={successMessage} /> : null}
            <DeleteEntityBox
                open={deleteJob}
                deleteMutation={deleteMutation}
                entityStatus={props.data.status}
                entityId={props.data.id}
                deleteLoading={deleteLoading}
                closeDelete={handleDeleteClose}
            />
        </StyledListRow>
    )
}

export default JobListRow
