import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { CANDIDATE_USERS_QUERIES } from '../../queries/usersListQueries'
import Loader from '../Loader'
import { Box, CircularProgress, Grid, Snackbar, Typography } from '@mui/material'
import UsersListRow from '../molecules/UsersListRow'
import variables from '../../settings/_variables.scss'

import Button from '../Button'

import _ from 'lodash'
import CustomCheckboxChip from '../elements/CustomCheckboxChip'
import SendCustomMessageModal from './SendCustomMessageModal'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CustomTablePagination from '../elements/CustomTablePagination'
import { EXPORT_CSV_MUTATION } from '../../queries/commonQueries'
import { useSearchParams } from 'react-router-dom'
import ListFilters from './ListFilters'
import { Send } from 'react-feather'
import DownloadIcon from '@mui/icons-material/Download'
import EmptyListSection from '../elements/EmptyListSection'

const UsersList = props => {
    const [currentQueryParameters, setSearchParams] = useSearchParams()
    const newQueryParameters = new URLSearchParams()
    const [pageNum, setPageNum] = useState(
        currentQueryParameters.get('page') ? Number(currentQueryParameters.get('page')) - 1 : 0
    )
    const [queryParams, setQueryParams] = useState(
        currentQueryParameters.get('query')
            ? JSON.parse(atob(currentQueryParameters.get('query')))
            : {}
    )
    const [batchSize] = useState(10)
    const [listLoader, setListLoader] = useState(false)
    const [downloadExcel, setDownloadExcel] = useState(false)
    const [allCheckboxChecked, setAllCheckboxChecked] = useState(false)
    const [checkedBoxes, setCheckedBoxes] = useState({})
    const [modalSendMessage, setModalSendMessage] = useState(false)
    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')
    const { loading, data, error, refetch } = useQuery(CANDIDATE_USERS_QUERIES, {
        fetchPolicy: 'network-only',
        variables: {
            userRole: props.selectedUserType,
            batchSize: batchSize,
            step: pageNum,
            ...queryParams
        },
        onCompleted(data) {
            setListLoader(false)
        }
    })

    const [exportCsvMutation, { loading: saveLoading }] = useMutation(EXPORT_CSV_MUTATION, {
        onCompleted(data) {
            setDownloadExcel(false)
            setToastMessage('Email sent to admin.')
            setShowToast(true)
        },
        onError(error) {}
    })
    useEffect(() => {
        let currentPage = currentQueryParameters.get('page') - 1
        if (currentPage > 0) {
            setPageNum(currentPage)
        }
    }, [currentQueryParameters.get('page')])

    useEffect(() => {
        let currentQuery = currentQueryParameters.get('query')
        if (currentQuery) {
            setQueryParams(JSON.parse(atob(currentQuery)))
        }
    }, [currentQueryParameters.get('query')])

    useEffect(() => {
        setAllCheckboxChecked(false)
        setCheckedBoxes({})
    }, [pageNum])

    if (loading) return <Loader />
    if (error) return <Box>{error.message}</Box>
    if (data) {
        const usersData = data.paginatedCandidates

        const checkAllCheckedBoxes = () => {
            let newState = {}
            usersData.forEach(candidate => {
                if (!allCheckboxChecked) {
                    newState[candidate.userId] = true
                }
            })
            setCheckedBoxes(newState)
            setAllCheckboxChecked(!allCheckboxChecked)
        }

        const updatedCheckedBoxes = candidateId => {
            const newState = Object.assign({}, checkedBoxes)
            newState[candidateId] = newState[candidateId] ? !newState[candidateId] : true
            setCheckedBoxes(newState)
            if (allCheckboxChecked) {
                setAllCheckboxChecked(false)
            } else if (
                Object.values(newState).filter(val => val === true).length === usersData.length
            ) {
                setAllCheckboxChecked(true)
            }
        }
        const handleToastClose = (event, reason) => {
            if (reason === 'clickaway') {
                return
            }

            setShowToast(false)
        }

        const showToastMessage = (showToast, message) => {
            setShowToast(showToast)
            setToastMessage(message)
        }

        const clearAllSelected = () => {
            setCheckedBoxes({})
            setAllCheckboxChecked(false)
        }

        return (
            <Box id='user-list-container'>
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={showToast}
                    autoHideDuration={3000}
                    onClose={handleToastClose}
                >
                    <Box
                        sx={{
                            backgroundColor: variables.BLUE,
                            maxWidth: '300px',
                            p: 2,
                            borderRadius: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            color: variables.WHITE,
                            textAlign: 'center',
                            width: '100%'
                        }}
                    >
                        <CheckCircleIcon sx={{ width: '20px', mr: 2 }} />
                        <strong>{` ${toastMessage}`}</strong>
                    </Box>
                </Snackbar>
                <Grid container spacing={{ xs: 1, sm: 3 }} alignItems='center'>
                    <Grid item md={4} lg={4} xs={6} sm={4}>
                        <Typography variant='h2' className='strong'>
                            Users List{data.candidatesCount > 0 ? ` (${data.candidatesCount})` : ''}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={8}
                        lg={8}
                        sm={8}
                        xs={6}
                        sx={{ textAlign: 'right', display: 'flex', justifyContent: 'end' }}
                    >
                        <Button
                            label={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Send
                                        sx={{
                                            color: variables.WHITE,
                                            fontSize: '20px'
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            display: {
                                                xs: 'none',
                                                md: 'block'
                                            },
                                            fontSize: '16px',
                                            fontWeight: '900',
                                            ml: { xs: 0, md: 1 }
                                        }}
                                    >
                                        Message Selected Users
                                    </Typography>
                                </Box>
                            }
                            color='primary'
                            variant='contained'
                            disabled={_.isEmpty(checkedBoxes)}
                            size='large'
                            sx={{ mr: 2, p: { xs: 1, sm: 2 } }}
                            onClick={e => {
                                e.preventDefault()
                                setModalSendMessage(true)
                            }}
                        />
                        <Button
                            label={
                                downloadExcel ? (
                                    <Box sx={{alignItems:'center', display: 'flex'}}>
                                        <CircularProgress
                                            size={20}
                                            sx={{ color: variables.BLUE, mr: {
                                                    xs: 0,
                                                    md: 2
                                                } }}
                                        />{' '}
                                        <Typography
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    md: 'block'
                                                },
                                                fontSize: '16px',
                                                fontWeight: '900'
                                            }}
                                        >
                                            Exporting to CSV
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <DownloadIcon
                                            sx={{
                                                color: variables.WHITE,
                                                fontSize: {
                                                    xs: '25px',
                                                    md: '20px'
                                                },
                                                mr: {
                                                    xs: 0,
                                                    md: 1
                                                }
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    md: 'block'
                                                },
                                                fontSize: '16px',
                                                fontWeight: '900'
                                            }}
                                        >
                                            Export to CSV
                                        </Typography>
                                    </Box>
                                )
                            }
                            color='primary'
                            variant='contained'
                            disabled={downloadExcel}
                            size='large'
                            sx={{ p: { xs: 1, sm: 2 } }}
                            onClick={e => {
                                e.preventDefault()
                                setDownloadExcel(true)
                                exportCsvMutation({
                                    variables: {
                                        requestType: 'user'
                                    }
                                })
                            }}
                        />
                        <SendCustomMessageModal
                            open={modalSendMessage}
                            handleClose={() => setModalSendMessage(false)}
                            checkedBoxes={checkedBoxes}
                            showToastMessage={showToastMessage}
                            clearAllSelected={clearAllSelected}
                        />
                    </Grid>
                </Grid>
                <Box>
                    <ListFilters
                        hideJobType={true}
                        queryParams={queryParams}
                        fetchData={queryData => {
                            newQueryParameters.set('page', 1)
                            newQueryParameters.set('query', queryData)
                            setSearchParams(newQueryParameters)
                            setPageNum(0)
                        }}
                    />
                </Box>
                <Grid container sx={{ my: 6 }}>
                    {usersData && usersData.length > 0 ? (
                        <Grid item md={4} lg={4} xl={3} sm={4} xs={12} sx={{ textAlign: 'left' }}>
                            <CustomCheckboxChip
                                chipSx={{
                                    m: 0,
                                    py: 1,
                                    px: 4
                                }}
                                label={allCheckboxChecked ? 'Deselect All' : 'Select All'}
                                // name={props.name[val]}
                                value='checkedReminder'
                                onChange={() => {
                                    checkAllCheckedBoxes()
                                }}
                                checked={allCheckboxChecked}
                            />
                        </Grid>
                    ) : null}
                </Grid>
                {usersData && usersData.length > 0 ? (
                    usersData.map((user, index) => (
                        <UsersListRow
                            uniqueId={index}
                            checkedBoxes={checkedBoxes}
                            userDetails={user}
                            checked={checkedBoxes[user.userId] || false}
                            onChecked={() => updatedCheckedBoxes(user.userId)}
                            refetch={refetch}
                            showToastMessage={showToastMessage}
                        />
                    ))
                ) : (
                    <EmptyListSection message='no users at the moment.'/>
                )}
                <CustomTablePagination
                    page={pageNum}
                    count={data.candidatesCount}
                    batchSize={batchSize}
                    handleChangePage={(e, pageNumber) => {
                        e.preventDefault()
                        newQueryParameters.set('page', pageNumber + 1)
                        if (currentQueryParameters.get('query')) {
                            newQueryParameters.set('query', currentQueryParameters.get('query'))
                        }
                        setSearchParams(newQueryParameters)
                        setPageNum(pageNumber)
                    }}
                />
            </Box>
        )
    }
}

export default UsersList
